import React, { useState } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { Box } from "@mui/system";

const StepTypeOptions = [
    {
        value: 'Custom Sql',
        label: 'CustomSql',
    },
    {
        value: 'Stored Procedure',
        label: 'StoredProcedure',
    }
];
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    border: '2px #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    height: 'auto',
};
const AddStepModal = () => {
    const [open, setOpen] = React.useState(false);
    const [filter, setFilter] = useState('');

    const handleChangeFilter = (event) => {
        setFilter(event.target.value);
    };
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box>
            <Box>
                <Button variant="contained" onClick={handleOpen}>Add Step</Button>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style }}>
                    Add Step
                    <Box>
                        <Box className="panels" sx={{ width: 'max-content', typography: 'body1' }}>

                            <Box className="connection_detail_container" sx={{ paddingTop: '1rem' }}>
                                <Box sx={{ marginTop: "1rem" }}>
                                    <TextField
                                        sx={{ width: '100%' }}
                                        id="outlined-basic"
                                        required
                                        variant="outlined" focused label="Step Id" />
                                </Box>
                                <Box sx={{ marginTop: "1rem" }}>
                                    <TextField
                                        sx={{ width: '100%' }}
                                        id="outlined-basic"
                                        required
                                        variant="outlined" focused label="Step Name" />
                                </Box>
                                <Box sx={{ marginTop: "1rem" }}>
                                    <TextField
                                        className="filter_field"
                                        select
                                        focused
                                        variant="outlined"
                                        sx={{ width: '100%' }}
                                        label="Step Type"
                                        value={filter}
                                        onChange={handleChangeFilter}
                                    >
                                        {StepTypeOptions.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Box>
                                <Box sx={{ marginTop: "1rem" }}>
                                    <TextField
                                        className="filter_field"
                                        select
                                        focused
                                        variant="outlined"
                                        sx={{ width: '100%' }}
                                        label="Job"
                                    >
                                        <MenuItem key='start job' value='start job'>start job</MenuItem>
                                        <MenuItem key='load data' value='load data'>load data</MenuItem>
                                    </TextField>
                                </Box>
                                <Box sx={{ marginTop: "1rem" }}>
                                    <TextField
                                        sx={{ width: '100%' }}
                                        id="outlined-basic"
                                        required
                                        variant="outlined" focused label="Command" />
                                </Box>
                                <Box sx={{ marginTop: "1rem" }}>
                                    <TextField
                                        className="filter_field"
                                        select
                                        focused
                                        variant="outlined"
                                        sx={{ width: '100%' }}
                                        label="On Success Action"
                                    >
                                        <MenuItem key='quit with success' value='quit with success'>quit with success</MenuItem>
                                        <MenuItem key='quit with failure' value='quit with failure'>quit with failure</MenuItem>
                                    </TextField>
                                </Box>
                                <Box sx={{ marginTop: "1rem" }}>
                                    <TextField
                                        className="filter_field"
                                        select
                                        focused
                                        variant="outlined"
                                        sx={{ width: '100%' }}
                                        label="On Fail Action"
                                    >
                                        <MenuItem key='quit with success' value='quit with success'>quit with success</MenuItem>
                                        <MenuItem key='quit with failure' value='quit with failure'>quit with failure</MenuItem>
                                    </TextField>
                                </Box>
                                <Box sx={{ marginTop: "1rem" }}>
                                    <TextField
                                        className="filter_field"
                                        select
                                        focused
                                        variant="outlined"
                                        sx={{ width: '100%' }}
                                        label="Data Source Name"
                                    >
                                        <MenuItem key='lyftron db' value='lyftron db'>lyftron db</MenuItem>
                                        <MenuItem key='targetdbsql' value='targetdbsql'>targetdbsql</MenuItem>
                                    </TextField>
                                </Box>

                                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }} >
                                    <Button variant="contained" onClick={handleClose} sx={{ margin: "8px" }} color="error">
                                        Cancel
                                    </Button>
                                    <Button variant="contained" sx={{ margin: "8px" }} color="success" >
                                        Create
                                    </Button>

                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal >
        </Box >
    )
};

export default AddStepModal;