import { Box } from "@mui/system";
import React from "react";
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import '../../assets/Styles/layout/header.scss';
const Navbar = () => {
    return (
        <Box className="header_container">
            <Box className="header_sub_container">
                <Box className="header_content_container">
                    <DirectionsRunIcon data-testid="DeleteIcon" />
                </Box>
                <Box className="header_content_container">
                    <HelpOutlineIcon />
                </Box>
                <Box className="header_content_container">
                    <NotificationsActiveIcon />
                </Box>
                <Box className="header_content_container">
                    <p>talha.bhutta@invozone.com</p>
                </Box>
                <Box className="header_content_container">
                    <LogoutIcon />
                </Box>
            </Box>
        </Box>
    )
}

export default Navbar;