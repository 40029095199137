import { Box } from "@mui/material";
import React from "react";
import Header from '../Components/Layout/Navbar'
import SideBar from '../Components/Layout/SideBar'
import MonitorComp from '../Components/MonitorComponenet';
const Monitor = () => {
  return (
    <Box>
      <Header />
      <Box className="dashboard_container">
        <SideBar />
        <MonitorComp />
      </Box>
    </Box>
  )
}

export default Monitor;    