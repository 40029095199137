import React, { Component } from "react";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import {
    MdCheckBox,
    MdCheckBoxOutlineBlank,
    MdChevronRight,
    MdKeyboardArrowDown,
    MdAddBox,
    MdIndeterminateCheckBox,
    MdFolder,
    MdFolderOpen,
    MdInsertDriveFile
} from "react-icons/md";
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import cloneDeep from 'lodash/cloneDeep';
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import '../../../../../../assets/Styles/Source/source.scss';

const nodesSources = [
    {
        value: "ParentOne",
        label: "ParentOne",
        children: [
            {
                value: "SectionOneChild",
                label: "SectionOneChild",

            }
        ]
    },
    {
        value: "ParentTwo",
        label: "ParentTwo",
        children: [
            {
                value: "ParentTwo-Child-1",
                label: "ParentTwo-Child-1"
            },
            {
                value: "ParentTwo-Child-2",
                label: "ParentTwo-Child-2"
            }
        ]
    }
];




const getNewArray = (arr) => {
    return map(arr, (e) => {
        var newElement = cloneDeep(e);
        newElement.label = `${newElement.label}_talha`
        var { children } = newElement;
        forEach(children, (x) => {
            x.label = `${x.label}_talha`
        })
        return newElement;
    });
}
class WidgetTree extends Component {
    state = {
        checked: [],
        expanded: [],
        checkedTarget: [],
        expandedTarget: [],
        arr: [nodesSources]
    };

    render() {
        const icons = {
            check: <MdCheckBox className="rct-icon rct-icon-check" />,
            uncheck: <MdCheckBoxOutlineBlank className="rct-icon rct-icon-uncheck" />,
            halfCheck: (
                <MdIndeterminateCheckBox className="rct-icon rct-icon-half-check" />
            ),
            expandClose: (
                <MdChevronRight className="rct-icon rct-icon-expand-close" />
            ),
            expandOpen: (
                <MdKeyboardArrowDown className="rct-icon rct-icon-expand-open" />
            ),
            expandAll: <MdAddBox className="rct-icon rct-icon-expand-all" />,
            collapseAll: (
                <MdIndeterminateCheckBox className="rct-icon rct-icon-collapse-all" />
            ),
            parentClose: <MdFolder className="rct-icon rct-icon-parent-close" />,
            parentOpen: <MdFolderOpen className="rct-icon rct-icon-parent-open" />,
            leaf: <MdInsertDriveFile className="rct-icon rct-icon-leaf-close" />
        };
        return (
            <>

                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ padding: '1rem', textAlign: 'center' }}>
                        <h5>Source Object</h5>
                        <CheckboxTree
                            nodes={nodesSources}
                            checked={this.state.checked}
                            expanded={this.state.expanded}
                            onCheck={checked => this.setState({ checked })}
                            onExpand={expanded => this.setState({ expanded })}
                            icons={icons}
                        />
                    </Box>
                    <Box sx={{ padding: '1rem', textAlign: 'center' }}>
                        <h5>Target Object</h5>
                        <CheckboxTree
                            // nodes={() => map(nodesSources, ({ label = '' }) => `${label} daud`)}
                            nodes={getNewArray(nodesSources)}
                            checked={this.state.checked}
                            expanded={this.state.expanded}
                            // onCheck={checked => this.setState({ checked })}
                            // onExpand={expanded => this.setState({ expanded })}
                            icons={icons} />

                    </Box>
                </Box>
                <Box sx={{ width: '100%', margin: "0.5rem" }}>
                    <Button variant="contained" sx={{ width: '100%' }}>Import</Button>
                </Box>
            </>
        );

    }
}
export default WidgetTree;
