import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CheckBox from '@mui/material/Checkbox';

function createData(name) {
    return { name };
}

const rows = [
    createData('Alter connection'),
    createData('Control connection'),
    createData('View connection'),
    createData('View connection details'),
];

export default function RoleRights() {
    return (
        <TableContainer style={{ width: '100%' }} component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Privilage</TableCell>
                        <TableCell align="left">Allow</TableCell>
                        <TableCell align="left">Grant</TableCell>
                        <TableCell align="left">Deny</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow>
                            <TableCell align="left">{row.name}</TableCell>
                            <TableCell align="left"><CheckBox /> </TableCell>
                            <TableCell align="left"><CheckBox /> </TableCell>
                            <TableCell align="left"><CheckBox /> </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}