import React from "react";
import Box from '@mui/material/Box';
import Logo from '../assets/media/Logo/Logo-blue.png';
import '../assets/Styles/ForgetPass/ForgetPass.scss';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link } from "react-router-dom";


const colorScehme = createTheme({
    palette: {
        primary: {
            main: '#0072FF',
        },
    },
});


const ForgetPass = () => {
    return (
        <Box className="forget_wrapper">
            <Box className="side_screen" >
                <Box className="side_screen_gif"></Box>
            </Box>
            <Box className="forget_container">

                <Box className="sub_container">
                    <img src={Logo} alt="" />
                    <Box className="field_container">
                        <h2>Password Updated</h2>
                        <h4>Your Password has been updated</h4>
                        <ThemeProvider theme={colorScehme}>

                            <Box className="forget_fields">
                            </Box>
                        </ThemeProvider>
                    </Box>

                    <Box className="new_account_section">
                        <p>Back to Login Page?
                            <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>
                                <span><strong>Sign in</strong></span>
                            </Link>
                        </p>
                    </Box>
                </Box>
            </Box>

        </Box>

    )
}
export default ForgetPass;