import React from "react";
import { Box } from "@mui/system";
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import DataTable from './DataTable';
import Modal from '@mui/material/Modal';
import TextareaAutosize from '@mui/material/TextareaAutosize';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  boxShadow: 24,
  p: 4,
};

const Schemas = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Box className="connection_container" sx={{ width: '100%' }} >
      <Box >
        <Toolbar style={{ paddingLeft: '13px', paddingTop: '20px', marginBottom: '20px' }}>
          <Box className='table_header_container' >
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, ml: 0 },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                className="search_field"
                variant="outlined"
                label="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box>
              <Button variant="contained" onClick={handleOpen}>Add Schema</Button>
            </Box>
          </Box>
        </Toolbar>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          <TextField
            id="outlined-helperText"
            label="Schema name*"
            defaultValue="Default Value"
            sx={{ width: '100%', margin: '0.5rem' }}
          />
          <TextField
            id="outlined-helperText"
            label="Physical name"
            defaultValue="Default Value"
            sx={{ width: '100%', margin: '0.5rem' }}
          />
          <TextareaAutosize
            minRows={7}
            placeholder="Comments"
            style={{ width: '100%', margin: '0.25rem' }}
          />
        </Box>
      </Modal>
      <DataTable />
    </Box>
  )
};

export default Schemas;