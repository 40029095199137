import * as React from 'react';
import { Box } from '@mui/system';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'white',
    border: '2px solid #1665C0',
    pt: 2,
    px: 4,
    pb: 3
};

function createData(name, parent, action) {
    return { name, parent, action };
}

const rows = [
    createData('APAC Data Owner', 'data OwnerShip', <Button variant='outlined'>Assign</Button>),
    createData('APAC Data Owner', 'data OwnerShip', <Button variant='outlined'>Assign</Button>),
    createData('APAC Data Owner', 'data OwnerShip', <Button variant='outlined'>Assign</Button>),
    createData('APAC Data Owner', 'data OwnerShip', <Button variant='outlined'>Assign</Button>),
    createData('APAC Data Owner', 'data OwnerShip', <Button variant='outlined'>Assign</Button>),

];

export default function AssignTable() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell align="left">Parent term</TableCell>
                        <TableCell align="left">Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row" onClick={handleOpen}>
                                {row.name}
                            </TableCell>
                            <TableCell align="left" onClick={handleOpen}>{row.parent}</TableCell>
                            <TableCell align="left">{row.action}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <div>
                <Modal
                    style={{ zIndex: '9999' }}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="parent-modal-title"
                    aria-describedby="parent-modal-description"
                >
                    <Box sx={{ ...style }}>
                        <Box sx={{ padding: '0.5rem', width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Button variant='contained'>Assign</Button>
                        </Box>
                        <Box >

                            <TextField
                                id="outlined-helperText"
                                label="Name"
                                defaultValue="Default Value"
                                sx={{ width: '100%', margin: '0.25rem' }}
                                disabled
                            />
                            <Box sx={{ fontSize: '12px' }}>
                                <span > Defination</span>
                                <TextareaAutosize
                                    label="Defination"
                                    minRows={7}
                                    style={{ width: '100%' }}
                                    disabled
                                />
                            </Box>
                            <Box sx={{ fontSize: '12px' }}>
                                <span > Description</span>
                                <TextareaAutosize
                                    label="Defination"
                                    minRows={7}
                                    style={{ width: '100%' }}
                                    disabled
                                />
                            </Box>
                        </Box>
                    </Box>
                </Modal>
            </div>
        </TableContainer>
    );
}
