import React from 'react';
import Login from './Pages/Login';
import Signup from './Pages/Signup';
import ForgetPass from './Pages/ForgetPass';
import ForgetPassConfirm from './Pages/ForgetPassConfirm';
import ForgetPassSuccess from './Pages/ForgetPassSuccess';
import Home from './Pages/Dashboard';
import Source from './Pages/Source';
import Target from './Pages/Target';
import Monitor from './Pages/Monitor';
import Connection from './Pages/Connection';
import WorkFlow from './Pages/WorkFlow';
import NewSource from './Pages/SubPages/Source/NewSource';
import SourceDetail from './Pages/SubPages/Source/SourceDetail';
import SourceNested from './Pages/SubPages/Source/SourceNested';
import TargetNested from './Pages/SubPages/Target/TargetNested';
import NewTarget from './Pages/SubPages/Target/NewTarget';
import NewConnection from './Pages/SubPages/Connection/NewConnection';
import ViewConnection from './Pages/SubPages/Connection/ViewConnection';
import EditConnection from './Pages/SubPages/Connection/EditConnection';
import Analyze from './Pages/Analyze';

import Catalog from './Pages/Catalog';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './App.css';
import TargetDetail from './Pages/SubPages/Target/TargetDetail';
import WorkFlowDetail from './Pages/SubPages/WorkFlow/WorkFlowDetail';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/Signup" element={<Signup />} />
          <Route path="/forgetPassword" element={<ForgetPass />} />
          <Route path="/forgetPasswordSuccess" element={<ForgetPassSuccess />} />
          <Route path="/forgetPasswordConfirm" element={<ForgetPassConfirm />} />
          <Route path="/home" element={<Home />} />
          <Route path="/source" element={<Source />} />
          <Route path="/newsource" element={<NewSource />} />
          <Route path="/viewsource" element={<SourceDetail />} />
          <Route path="/viewsourcenested" element={<SourceNested />} />
          <Route path="/target" element={<Target />} />
          <Route path="/newtarget" element={<NewTarget />} />
          <Route path="/viewtarget" element={<TargetDetail />} />
          <Route path="/viewtargetnested" element={<TargetNested />} />
          <Route path="/workFlow" element={<WorkFlow />} />
          <Route path="/viewworkflow" element={<WorkFlowDetail />} />
          <Route path="/monitor" element={<Monitor />} />
          <Route path="/connection" element={<Connection />} />
          <Route path="/newconnection" element={<NewConnection />} />
          <Route path="/viewconnection" element={<ViewConnection />} />
          <Route path="/editconnection" element={<EditConnection />} />
          <Route path="/analyze" element={<Analyze />} />
          <Route path="/catalog" element={<Catalog />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
