import React, { useState } from "react";
import { Box } from "@mui/system";
import '../../../../assets/Styles/Target/target.scss'
import TextField from '@mui/material/TextField';
import { Button } from "@mui/material";

const Summary = () => {
    const [edit, setEdit] = useState(true);
    const [tableName, setTableName] = useState('Some Table Name');
    const [fTableName, setFTableName] = useState('Some Table Name');
    const [pTableName, setPTableName] = useState('Some Table Name');
    const [comment, setComment] = useState('Some Table Name');
    return (
        <Box sx={{ overflowX: 'hidden' }}>
            <Box sx={{ width: "95%", display: 'flex', justifyContent: 'flex-end', alignItems: "center", marginTop: '1rem' }}>
                <Button variant="contained" onClick={() => setEdit(false)}>Edit</Button>
            </Box>
            <Box className="statements_fields">
                <TextField sx={{ width: '95%', marginTop: "16px" }} value={tableName} onChange={(e) => setTableName(e.target.value)} disabled={edit} id="outlined-basic" variant="outlined" label="Normalized table name" InputLabelProps={{ shrink: true }} />
            </Box>
            <Box className="statements_fields">
                <TextField sx={{ width: '95%', marginTop: "16px" }} value={fTableName} onChange={(e) => setFTableName(e.target.value)} disabled={edit} id="outlined-basic" variant="outlined" label="Full normalized table name" InputLabelProps={{ shrink: true }} />
            </Box>
            <Box className="statements_fields">
                <TextField sx={{ width: '95%', marginTop: "16px" }} value={pTableName} onChange={(e) => setPTableName(e.target.value)} disabled={edit} id="outlined-basic" variant="outlined" label="Physical table name" InputLabelProps={{ shrink: true }} />
            </Box>
            <Box className="statements_fields">
                <TextField sx={{ width: '95%', marginTop: "16px" }} value={comment} onChange={(e) => setComment(e.target.value)} disabled={edit} id="outlined-basic" variant="outlined" label="Comment" InputLabelProps={{ shrink: true }} />
            </Box>
            {
                edit ? ('') : (
                    <Box sx={{ width: "95%", display: 'flex', justifyContent: 'flex-end', alignItems: "center", marginTop: '1rem' }}>
                        <Button sx={{ marginRight: '1rem' }} color="error" variant="contained" onClick={() => setEdit(true)}>Cancel</Button>
                        <Button variant="contained" color="success" >Update</Button>
                    </Box>
                )
            }
        </Box>
    )
};

export default Summary;