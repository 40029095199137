import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import CheckBox from '@mui/material/Checkbox';
import Logo from '../assets/media/Logo/Logo-blue.png';
import '../assets/Styles/Signup/signup.scss';
import { Link } from "react-router-dom";



const Signup = () => {
    const intialValues = { firstName: "", lastName: "", email: "", contact: "", company: "" };
    const [formValues, setFormValues] = useState(intialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const submitForm = () => {
        console.log(formValues);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmitting(true);
    };

    const validate = (values) => {
        let errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!values.firstName) {
            errors.firstName = "Field is required*";
        }
        if (!values.lastName) {
            errors.lastName = "Field is required*";
        }
        if (!values.contact) {
            errors.contact = "Field is required*";
        }
        if (!values.company) {
            errors.company = "Field is required*";
        }
        if (!values.email) {
            errors.email = "Email is required*";
        } else if (!regex.test(values.email)) {
            errors.email = "Invalid email format";
        }
        return errors;
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmitting) {
            submitForm();
        }
    }, [formErrors]);
    return (
        <Box className="signup_wrapper">
            <Box className="side_screen" >
                <Box className="side_screen_gif"></Box>
            </Box>
            <Box className="signup_container">
                <Box className="sub_container">
                    <img src={Logo} alt="" />
                    <Box className="field_container">
                        {Object.keys(formErrors).length === 0 && isSubmitting && (
                            <span className="success-msg">Signed up successfully</span>
                        )}
                        <form onSubmit={handleSubmit} noValidate>
                            <Box className="signup_fields">
                                <TextField
                                    className="field" id="outlined-basic" variant="outlined" label="First Name"
                                    type="text"
                                    name="fName"
                                    value={formValues.firstName}
                                    onChange={handleChange}
                                    helperText={formErrors.firstName && formErrors.firstName}
                                    InputLabelProps={{
                                        style: { color: '#cbc3c3' },
                                    }}
                                />
                            </Box>
                            <Box className="signup_fields">
                                <TextField
                                    className="field" id="outlined-basic" variant="outlined" label="Last Name"
                                    type="text"
                                    name="lastName"
                                    value={formValues.lastName}
                                    onChange={handleChange}
                                    helperText={formErrors.lastName && formErrors.lastName}
                                    InputLabelProps={{
                                        style: { color: '#cbc3c3' },
                                    }}
                                />
                            </Box>
                            <Box className="signup_fields">
                                <TextField
                                    className="field" id="outlined-basic" variant="outlined" label="Email"
                                    type="email"
                                    name="email"
                                    value={formValues.email}
                                    onChange={handleChange}
                                    helperText={formErrors.email && formErrors.email}
                                    InputLabelProps={{
                                        style: { color: '#cbc3c3' },
                                    }}
                                />
                            </Box>
                            <Box className="signup_fields">
                                <TextField
                                    className="field" id="outlined-basic" variant="outlined" label="Contact"
                                    type="text"
                                    name="contact"
                                    value={formValues.contact}
                                    onChange={handleChange}
                                    helperText={formErrors.contact && formErrors.contact}
                                    InputLabelProps={{
                                        style: { color: '#cbc3c3' },
                                    }}
                                />
                            </Box>
                            <Box className="signup_fields">
                                <TextField
                                    className="field" id="outlined-basic" variant="outlined" label="Company"
                                    type="text"
                                    name="company"
                                    value={formValues.company}
                                    onChange={handleChange}
                                    helperText={formErrors.company && formErrors.company}
                                    InputLabelProps={{
                                        style: { color: '#cbc3c3' },
                                    }}
                                />
                            </Box>
                            <Box className="terms_policy">
                                <Box className="checkbox_container">
                                    <CheckBox /> <span> <strong>Accept the Terms of Use & Privacy Policy</strong></span>
                                </Box>
                            </Box>
                            <Box className="signup_btn">
                                {/* <Link to="/home" style={{ textDecoration: 'none', color: 'black' }}> */}
                                <button type="submit" >Submit</button>
                                {/* </Link> */}
                            </Box>
                        </form>
                        <Box className="new_account_section">
                            <p>Already have an account?
                                <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>
                                    <span><strong>Sign in</strong></span>
                                </Link>
                            </p>
                        </Box>
                    </Box>
                </Box>
            </Box>

        </Box>
    )
}
export default Signup;