import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import CheckBox from '@mui/material/Checkbox';
import Logo from '../assets/media/Logo/Logo-blue.png';
import '../assets/Styles/Login/Login.scss';
import { Link } from "react-router-dom";
const Login = () => {
    const intialValues = { email: "", password: "" };
    const [formValues, setFormValues] = useState(intialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const submitForm = () => {
        console.log(formValues);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmitting(true);
    };

    const validate = (values) => {
        let errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!values.email) {
            errors.email = "Email is required*";
        } else if (!regex.test(values.email)) {
            errors.email = "Invalid email format";
        }
        if (!values.password) {
            errors.password = "Password in required*";
        } else if (values.password.length < 4) {
            errors.password = "Password must be more than 4 characters";
        }
        return errors;
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmitting) {
            submitForm();
        }
    }, [formErrors]);
    return (

        <Box className="login_wrapper">
            <Box className="side_screen" >
                <Box className="side_screen_gif"></Box>
            </Box>
            <Box className="login_container">
                <Box className="sub_container">
                    <img src={Logo} alt="" />
                    <Box className="field_container">
                        {Object.keys(formErrors).length === 0 && isSubmitting && (
                            <span className="success-msg">Signed in successfully</span>
                        )}
                        <form onSubmit={handleSubmit} noValidate>
                            <Box className="login_fields">
                                <TextField
                                    className="field" id="outlined-basic" variant="outlined" label="Email"
                                    type="email"
                                    name="email"
                                    value={formValues.email}
                                    onChange={handleChange}
                                    helperText={formErrors.email && formErrors.email}
                                    InputLabelProps={{
                                        style: { color: '#cbc3c3' },
                                    }}
                                />
                            </Box>
                            <Box className="login_fields">
                                <TextField
                                    className="field" id="outlined-basic" variant="outlined" label="Password"
                                    type="password"
                                    name="password"
                                    value={formValues.password}
                                    onChange={handleChange}
                                    helperText={formErrors.password && formErrors.password}
                                    InputLabelProps={{
                                        style: { color: '#cbc3c3' },
                                    }}
                                />
                            </Box>
                            <Box className="remember_me_section">
                                <Box className="checkbox_container">
                                    <CheckBox /> <span> <strong>Remember me</strong></span>
                                </Box>
                                <Link to="/forgetPassword" style={{ textDecoration: 'none', color: 'black' }}>
                                    <p>Forgot password?</p>
                                </Link>
                            </Box>
                            <Box className="login_btn">
                                <Link to="/home" style={{ textDecoration: 'none', color: 'black' }}>
                                    <button type="submit" >Submit</button>
                                </Link>
                            </Box>
                        </form>
                        <Box className="new_account_section">

                            <p>Don't have an account?
                                <Link to="/signup" style={{ textDecoration: 'none', color: 'black' }}>
                                    <span><strong>Register today</strong></span>
                                </Link>
                            </p>
                        </Box>
                    </Box>
                </Box>
            </Box>

        </Box>
    )
}
export default Login;