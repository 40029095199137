import React from 'react';
import { Box } from "@mui/system";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import '../../../assets/Styles/Connection/connection.scss';
import SourceDataBase from '../../../Components/SourceComponent/NewSource/SourceDataBase';
import ConnectionDetails from '../../../Components/SourceComponent/NewSource/ConnectionDetails';
import MetaData from '../../../Components/SourceComponent/NewSource/MetaData/MetaData';
import Header from '../../../Components/Layout/Navbar';
import SideBar from '../../../Components/Layout/SideBar';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SourceProvider from '../../../Components/SourceComponent/NewSource/SourceProvider/SourceProvider';

const steps = ['Source Database', 'Source Provider', 'Provider Parameter', 'Import Metadata'];


const NewSource = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return step in [0, 1, 2, 3];
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const navigate = useNavigate();
  return (
    <Box>
      <Header />
      <Box className="dashboard_container">
        <SideBar />
            <Box id="scrollbar_style" className="main_cont">
      <Box class="second_cont">
        <Box className="new_connection_container">
          <Box className="page_title">
            <h3>ADD NEW SOURCE</h3>
            <ArrowBackIcon onClick={() => navigate(-1)} />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )}
            <Button onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </Box>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Stepper sx={{ width: '100%' }} activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  if (isStepOptional(index)) {
                    console.log("index", index);
                    if (index === 0)
                      labelProps.optional = (
                        <Typography variant="caption"></Typography>
                      );
                    else if (index === 1) {
                      labelProps.optional = (
                        <Typography variant="caption"></Typography>
                      );
                    }
                    else if (index === 2) {
                      labelProps.optional = (
                        <Typography variant="caption"></Typography>
                      );
                    }
                    else if (index === 3) {
                      labelProps.optional = (
                        <Typography variant="caption"></Typography>
                      );
                    }
                  }
                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>

            </Box>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {activeStep === 0 && <SourceDataBase />}
                {activeStep === 1 && <SourceProvider />}
                {activeStep === 2 && <ConnectionDetails />}
                {activeStep === 3 && <MetaData />}
              </React.Fragment>
            )}
          </Box>
        </Box>
        </Box>
        </Box>
      </Box>
    </Box>
  )
}
export default NewSource;