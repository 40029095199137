import { Box } from "@mui/material";
import React from "react";
import Header from '../../../Components/Layout/Navbar'
import SideBar from '../../../Components/Layout/SideBar'
import TargetDetailComp from '../../../Components/TargetComponent/TargetDetail/index.js';
const TargetDetail = () => {
    return (
        <Box>
            <Header />
            <Box className="dashboard_container">
                <SideBar />
                <TargetDetailComp />
            </Box>
        </Box>
    )
}

export default TargetDetail;