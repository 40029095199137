import React from 'react';
import { Box } from '@mui/system';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import '../../../assets/Styles/Connection/connection.scss';
function createData(name, provider) {
  return { name, provider };
}

const rows = [
  createData('invo_data', 'MySQL'),
  createData('invo_chat', 'Mongo'),
  createData('invo_com', 'MariaDB')
];

const DataSources = () => {
  return (
    <Box className="provider_detail_container" style={{ width: '100%', paddingRight: '1rem', overflow: 'hidden' }} >
      <TableContainer style={{ width: '100%' }} component={Paper} >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="left">Provider</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
              >
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="left">{row.provider}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

    </Box>
  );
}

export default DataSources;