import React, { useState } from 'react';
import { Box } from "@mui/system";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import '../../../assets/Styles/Connection/connection.scss';

const DialectOptions = [
    {
        value: 'NewItem',
        label: 'New Item',
    },
    {
        value: 'ExistingItem',
        label: 'Existing Item',
    }
];

const SourceDataBase = () => {
    const [dialect, setDialect] = useState('Accelo');

    return (
        <Box sx={{ display: 'flex' }}>
            <Box className="connection_detail_container" >
                <Box>
                    <TextField
                        className="connection_name_field"
                        select
                        focused
                        label="Select Connection"
                        value={dialect}
                        onChange={(e) => setDialect(e.target.value)}
                    >
                        {DialectOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Box>
                <Box>
                    <TextField
                        className="connection_name_field"
                        id="outlined-basic"
                        variant="outlined" focused label="DataTarget Name" />
                </Box>
                <Box className="connection_btn">
                    <button>
                        Cancel
                    </button>
                    <button>
                        Next
                    </button>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '50%', background: "#FAFAFA" }} >
                <h2>Source Detail Guide</h2>
                <p>(Coming Soon)</p>
            </Box>
        </Box>
    )
}
export default SourceDataBase;