import { Box } from "@mui/material";
import React from "react";
import Header from '../../../Components/Layout/Navbar'
import SideBar from '../../../Components/Layout/SideBar'
import TargetDetailNestedComponent from "../../../Components/TargetComponent/TargetDetailNested";
const TargetNested = () => {
    return (
        <Box>
            <Header />
            <Box className="dashboard_container">
                <SideBar />
                <TargetDetailNestedComponent />
            </Box>
        </Box>
    )
}

export default  TargetNested;