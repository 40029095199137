import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { visuallyHidden } from '@mui/utils';

function createData(columnname, distinctcount, averagelengthbytes, calculatestatistics) {
  return {
    columnname,
    distinctcount,
    averagelengthbytes,
    calculatestatistics
  };
}

const rows = [
  createData('columnname', 'distinctcount', 'averagelengthbytes',
    <Checkbox
      color="primary"
    />),
  createData('columnname', 'distinctcount', 'averagelengthbytes',
    <Checkbox
      color="primary"
    />),
  createData('columnname', 'distinctcount', 'averagelengthbytes',
    <Checkbox
      color="primary"
    />),
  createData('columnname', 'distinctcount', 'averagelengthbytes',
    <Checkbox
      color="primary"
    />),
  createData('columnname', 'distinctcount', 'averagelengthbytes',
    <Checkbox
      color="primary"
    />),
  createData('columnname', 'distinctcount', 'averagelengthbytes',
    <Checkbox
      color="primary"
    />),
  createData('columnname', 'distinctcount', 'averagelengthbytes',
    <Checkbox
      color="primary"
    />),
  createData('columnname', 'distinctcount', 'averagelengthbytes',
    <Checkbox
      color="primary"
    />),
  createData('columnname', 'distinctcount', 'averagelengthbytes',
    <Checkbox
      color="primary"
    />),
  createData('columnname', 'distinctcount', 'averagelengthbytes',
    <Checkbox
      color="primary"
    />),
  createData('columnname', 'distinctcount', 'averagelengthbytes',
    <Checkbox
      color="primary"
    />),
  createData('columnname', 'distinctcount', 'averagelengthbytes',
    <Checkbox
      color="primary"
    />),
  createData('columnname', 'distinctcount', 'averagelengthbytes',
    <Checkbox
      color="primary"
    />),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'columnname',
    numeric: false,
    disablePadding: true,
    label: 'Column Name'
  },
  {
    id: 'distinctcount',
    numeric: false,
    disablePadding: true,
    label: 'Distinct Count',
  },
  {
    id: 'averagelengthbytes',
    numeric: false,
    disablePadding: true,
    label: 'Average Length Bytes',
  },
  {
    id: 'calculatestatistics',
    numeric: false,
    disablePadding: true,
    label: 'Calculate Statistics',
  }
];

function EnhancedTableHead(props) {
  const { order, orderBy, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'left' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{ paddingLeft: '14px', fontWeight: '600', fontSize: '16px', paddingTop: '10px', paddingBottom: '10px' }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}
EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const EnhancedTableToolbar = (props) => {
  return (
    <Box>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },

        }}
      >
      </Toolbar>
    </Box>
  );
};
export default function EnhancedTable() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('category');
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <Box>
      {/* <CustomModal/> */}
      {/* <Button onClick={handleOpen}>
        Edit
      </Button> */}
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableContainer sx={{ width: '100%', overflow: 'auto', maxHeight: '50vh' }} id="scrollbar_style">
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.name}
                      >
                        <TableCell align="left">{row.columnname}</TableCell>
                        <TableCell align="left">{row.distinctcount}</TableCell>
                        <TableCell align="left">{row.averagelengthbytes}</TableCell>
                        <TableCell align="left">{row.calculatestatistics}</TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
      </Box>
    </Box>
  );
}