import React from "react";
import { Box } from "@mui/system";
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import ExecutionPlanCacheTable from './DataTable';

const ExecutionPlanCache = () => {
  return (
    <Box className="connection_container" sx={{ width: '100%' }} >
      <Box >
        <Toolbar style={{ paddingLeft: '14px', paddingTop: '10px' }}>
          <Box className='table_header_container' >
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, ml: 0 },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                className="search_field"
                variant="outlined"
                label="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        </Toolbar>
      </Box>
      <ExecutionPlanCacheTable />
    </Box>
  )
};

export default ExecutionPlanCache;