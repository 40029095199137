import React from 'react';
import '../../assets/Styles/Dashboard/dashboard.scss';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box } from "@mui/system";

const statCards = (props) => {
  return (
    <Box className="item" >
      <Card variant="outlined" className="stat_card">
        <CardContent>
          <Typography variant="h6" component="div">
            {props.title}
          </Typography>
          <Typography sx={{ mb: 0.5 }} color="text.secondary">
            {props.subtitle}
          </Typography>
          <Typography variant="h4" component="div">
            {props.data}
          </Typography>
        </CardContent></Card>
    </Box>
  )
}

export default statCards;