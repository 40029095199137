import React from 'react';
import { Box } from "@mui/system";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import '../../../assets/Styles/Connection/connection.scss';
import EditConnectionDetails from '../../../Components/ConnectionComponent/EditConnection/EditConnectionDetails';
import InvodataNode from '../../../Components/ConnectionComponent/EditConnection/InvoDataNode';
import Header from '../../../Components/Layout/Navbar';
import SideBar from '../../../Components/Layout/SideBar';

const NewConnection = () => {
    const navigate = useNavigate();
    return (
        <Box>
            <Header />
            <Box className="dashboard_container">
                <SideBar />
                <Box id="scrollbar_style" className="main_cont">
                    <Box class="second_cont">
                        <Box className="new_connection_container">
                            <Box className="page_title">
                                <h3>EDIT CONNECTION</h3>
                                <ArrowBackIcon onClick={() => navigate(-1)} />
                            </Box>
                            <Grid container >
                                <Grid item xs={6} style={{ width: '100%', height: '100vh' }}>

                                    <EditConnectionDetails />
                                </Grid>
                                <Grid item xs={6} style={{ width: '100%', height: '100vh', background: '#FAFAFA' }}>

                                    <InvodataNode />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
export default NewConnection;