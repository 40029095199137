import React, { useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createModalData(name, enabled, description, action) {
    return {
        name,
        enabled,
        description,
        action
    };
}
const datatablerows = [
    createModalData('name', 'enabled', 'description', 'action'),
    createModalData('name', 'enabled', 'description', 'action'),
    createModalData('name', 'enabled', 'description', 'action'),
    createModalData('name', 'enabled', 'description', 'action'),
    createModalData('name', 'enabled', 'description', 'action'),
    createModalData('name', 'enabled', 'description', 'action'),
];
const Schedules = () => {

    return (
        <TableContainer component={Paper} id="scrollbar_style" sx={{ maxHeight: 510, overflowY: 'auto' }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Name	</TableCell>
                        <TableCell>Eabled	</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody >
                    {datatablerows.map((dtrow) => (
                        <TableRow
                            key={dtrow.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {dtrow.name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {dtrow.enabled}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {dtrow.name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {dtrow.enabled}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default Schedules;