import React, { useState } from 'react';
import { Box } from "@mui/system";
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import '../../../../assets/Styles/Connection/connection.scss';

const categoryOption = [
  {
    value: 'Acounting',
    label: 'Acounting',
  },
  {
    value: 'Ads',
    label: 'Ads',
  },
  {
    value: 'Big Data',
    label: 'Big Data',
  }

];



export const SourceProviderHeader = () => {
  const [category, setCategory] = useState('Ads');

  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
  }
  return (
    <Box >
      <Toolbar style={{ paddingLeft: '14px', paddingTop: '10px' }}>
        <Box className='table_header_container' >
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, ml: 0 },
            }}
            noValidate
            autoComplete="off"
          >

            <TextField
              className="category_field_P"
              select
              label="Select Category"
              value={category}
              onChange={handleChangeCategory}
              sx={{width: '135px !important'}}
            >
              {categoryOption.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              className="search_field_P"
              variant="outlined"
              label="Search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
      </Toolbar>
    </Box>
  )


}