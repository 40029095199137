import React, { useState } from "react";
import { Box } from "@mui/system";
import { Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import '../../assets/Styles/Source/source.scss';
import SourceTable from './DataTable';

const categoryOption = [
  {
    value: 'Acounting',
    label: 'Acounting',
  },
  {
    value: 'Ads',
    label: 'Ads',
  },
  {
    value: 'Big Data',
    label: 'Big Data',
  }
];
const Source = () => {
  const [category, setCategory] = useState('Ads');
  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
  }
  return (
    <Box id="scrollbar_style" className="main_cont">
      <Box class="second_cont">
        <Box className="connection_container">
          <Box >
            <Toolbar style={{ paddingLeft: '0px', paddingTop: '10px', paddingRight: '0px', paddingBottom: '20px' }}>
              <Box className='table_header_container' >
                <Box
                  component="form"
                  sx={{
                    '& .MuiTextField-root': { m: 1, ml: 0 },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    className="search_field"
                    variant="outlined"
                    label="Search"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    className="category_field"
                    select
                    label="Select Category"
                    value={category}
                    onChange={handleChangeCategory}
                  >
                    {categoryOption.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
                <Box>
                  <Link variant="outlined" to="/newsource" style={{ textDecoration: 'none' }}>
                    <Button variant="contained">
                      Add New Source
                    </Button>
                  </Link>
                </Box>
              </Box>
            </Toolbar>
          </Box>
          <SourceTable />
        </Box>
      </Box>
    </Box>
  )
};

export default Source;