import React, { useState } from "react";
import { Box } from "@mui/system";
import { Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import DataTable from './DataTable';
import AddScheduleModal from "./AddScheduleModal";

const Schedules = () => {

  return (
    <Box className="connection_container" sx={{ width: '100%' }} >
      <Box >
        <Toolbar style={{ paddingLeft: '13px', paddingTop: '20px', marginBottom: '20px' }}>
          <Box className='table_header_container' >
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, ml: 0 },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                className="search_field"
                variant="outlined"
                label="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <AddScheduleModal />

          </Box>
        </Toolbar>
      </Box>
      <DataTable />
    </Box>
  )
};

export default Schedules;