import React, { useState } from "react";
import { Box } from "@mui/system";
import '../../assets/Styles/Target/target.scss';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import StatementHistory from "./MonitorTabs/StatementHistory/StatementHistory";
import WorkFlowHistory from "./MonitorTabs/WorkFlowHistory/WorkFlowHistory";
import ActiveStatements from "./MonitorTabs/ActiveStatements/ActiveStatements";
import ClientConnections from "./MonitorTabs/ClientConnections/ClientConnections";
import ExecutionPlanCache from "./MonitorTabs/ExecutionPlanCache/ExecutionPlanCache";
import RunningWorkFlows from "./MonitorTabs/RunningWorkFlows/RunningWorkFlows";
import BrokenPipeLines from "./MonitorTabs/BrokenPipeLines/BrokenPipeLines";
import '../MonitorComponenet/monitorComp.scss';
const Monitor = () => {

  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box id="scrollbar_style" className="main_cont">
      <Box class="second_cont">
        <Box className="new_connection_container">
          <Box className="panels" sx={{ typography: 'body1' }}>
            <TabContext isVertical value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList isVertical onChange={handleChange} aria-label="lab API tabs example " style={{ background: 'white', width: '100%' }}>
                  <Tab label="Statement History" value="1" />
                  <Tab label="WorkFlow History" value="2" />
                  <Tab label="Active Statements" value="3" />
                  <Tab label="Client Connections" value="4" />
                  <Tab label="Execution Plan Cache" value="5" />
                  <Tab label="Running WorkFlows" value="6" />
                  <Tab label="Broken Pipelines" value="7" />
                </TabList>
              </Box>
              <TabPanel className="container" style={{ padding: '0px', width: '100%', height: '100%' }} value="1">
                <StatementHistory />
              </TabPanel>
              <TabPanel className="container" style={{ padding: '0px', width: '100%', height: '100%' }} value="2">
                <WorkFlowHistory />
              </TabPanel>
              <TabPanel className="container" style={{ padding: '0px', width: '100%', height: '100%' }} value="3">
                <ActiveStatements />
              </TabPanel>
              <TabPanel className="container" style={{ padding: '0px', width: '100', height: '100%' }} value="4">
                <ClientConnections />
              </TabPanel><TabPanel className="container" style={{ padding: '0px', width: '100%', height: '100%' }} value="5">
                <ExecutionPlanCache />
              </TabPanel>
              <TabPanel className="container" style={{ padding: '0px', width: '100', height: '100%' }} value="6">
                <RunningWorkFlows />
              </TabPanel><TabPanel className="container" style={{ padding: '0px', width: '100%', height: '100%' }} value="7">
                <BrokenPipeLines />
              </TabPanel>
            </TabContext>
          </Box>
        </Box>
      </Box>
    </Box>
  )
};

export default Monitor;