import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Button from '@mui/material/Button';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};
export default function TagModal() {
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box>
            <Box className='action_container' sx={{ display: 'flex', justifyContent: 'left', paddingLeft: '0px' }}>
                <Button onClick={handleOpen} sx={{ display: 'flex', justifyContent: 'left', paddingLeft: '0px' }} >
                    <RemoveRedEyeIcon />
                </Button>
                <Button sx={{ display: 'flex', justifyContent: 'left', paddingLeft: '0px' }} >Remove</Button>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style }}>
                    <Box>
                        <Box className="panels" sx={{ width: 'max-content', typography: 'body1' }}>
                            <TabContext isVertical value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList isVertical onChange={handleChange} aria-label="lab API tabs example " style={{ background: 'white' }}>
                                        <Tab label="Load Data" value="1" />
                                    </TabList>
                                </Box>
                                <TabPanel className="container" value="1">
                                    <Box className="field_container">
                                        <Box className="statements_fields">
                                            <TextField className="field" value="Public" disabled id="outlined-basic" variant="outlined" label="Name" InputLabelProps={{ shrink: true }} />
                                        </Box>
                                        <Box className="statements_fields">
                                            <TextField className="field" value="Parent business term(s)" disabled id="outlined-basic" variant="outlined" label="Commercial Data Classification" InputLabelProps={{ shrink: true }} />
                                        </Box>
                                        <Box className="statements_fields">
                                            <TextField className="field" value="Some Defination" disabled id="outlined-basic" variant="outlined" label="Definition" InputLabelProps={{ shrink: true }} />
                                        </Box>
                                        <Box className="statements_fields">
                                            <TextField className="field" value="Some Description" disabled id="outlined-basic" variant="outlined" label="Description" InputLabelProps={{ shrink: true }} />
                                        </Box>
                                    </Box>
                                </TabPanel>
                            </TabContext>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}