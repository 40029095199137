import React, { useState } from "react";
import Box from '@mui/material/Box';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import LoadData from "./Tabs/LoadData";
import Schedules from "./Tabs/Schedules";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  height: 600,
  bgcolor: 'background.paper',
  border: '2px #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
function createData(task, status, lastrun, Actions) {
  return {
    task,
    status,
    lastrun,
    Actions
  };
}
const rows = [
  createData('task', 'status', 'lastrun', 'Run Schedule', <Box className='action_container'></Box>),
  createData('task', 'status', 'lastrun', 'Run Schedule', <Box className='action_container'></Box>),
  createData('task', 'status', 'lastrun', 'Run Schedule', <Box className='action_container'></Box>),
  createData('task', 'status', 'lastrun', 'Run Schedule', <Box className='action_container'></Box>),
  createData('task', 'status', 'lastrun', 'Run Schedule', <Box className='action_container'></Box>),
  createData('task', 'status', 'lastrun', 'Run Schedule', <Box className='action_container'></Box>),
  createData('task', 'status', 'lastrun', 'Run Schedule', <Box className='action_container'></Box>),
  createData('task', 'status', 'lastrun', 'Run Schedule', <Box className='action_container'></Box>),
  createData('task', 'status', 'lastrun', 'Run Schedule', <Box className='action_container'></Box>),
  createData('task', 'status', 'lastrun', 'Run Schedule', <Box className='action_container'></Box>),
  createData('task', 'status', 'lastrun', 'Run Schedule', <Box className='action_container'></Box>),
  createData('task', 'status', 'lastrun', 'Run Schedule', <Box className='action_container'></Box>),
  createData('task', 'status', 'lastrun', 'Run Schedule', <Box className='action_container'></Box>),
  createData('task', 'status', 'lastrun', 'Run Schedule', <Box className='action_container'></Box>),
  createData('task', 'status', 'lastrun', 'Run Schedule', <Box className='action_container'></Box>),
  createData('task', 'status', 'lastrun', 'Run Schedule', <Box className='action_container'></Box>),
  createData('task', 'status', 'lastrun', 'Run Schedule', <Box className='action_container'></Box>),
  createData('task', 'status', 'lastrun', 'Run Schedule', <Box className='action_container'></Box>),
  createData('task', 'status', 'lastrun', 'Run Schedule', <Box className='action_container'></Box>),
  createData('task', 'status', 'lastrun', 'Run Schedule', <Box className='action_container'></Box>),
  createData('task', 'status', 'lastrun', 'Run Schedule', <Box className='action_container'></Box>),

];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'task',
    numeric: false,
    disablePadding: true,
    label: 'Task'
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    label: 'Status',
  },
  {
    id: 'lastrun',
    numeric: false,
    disablePadding: true,
    label: 'Last Run',
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: true,
    label: 'Action',
  }
];
export default function ManagementTasksModal() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('1');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box>
      <Box className='action_container' sx={{ display: 'flex', justifyContent: 'left', paddingLeft: '0px' }}>
        <Button onClick={handleOpen} sx={{ display: 'flex', justifyContent: 'left', paddingLeft: '0px' }} >
          <RemoveRedEyeIcon />
        </Button>
        <Button sx={{ display: 'flex', justifyContent: 'left', paddingLeft: '0px' }} >run</Button>
        <Button sx={{ display: 'flex', justifyContent: 'left', paddingLeft: '0px' }} >schedule</Button>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style }}>
          <Box>
            <Box className="panels" sx={{ width: 'max-content', typography: 'body1' }}>
              <TabContext isVertical value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList isVertical onChange={handleChange} aria-label="lab API tabs example " style={{ background: 'white' }}>
                    <Tab label="Load Data" value="1" />
                    <Tab label="Schedules" value="2" />
                  </TabList>
                </Box>
                <TabPanel className="container" value="1">
                  <LoadData />
                </TabPanel>
                <TabPanel className="container" value="2">
                  <Schedules />
                </TabPanel>
              </TabContext>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}