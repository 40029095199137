import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CheckBox from '@mui/material/Checkbox';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import '../../../../SourceComponent/source.scss'

const Email = () => {
    const [edit, setEdit] = React.useState(true);

    const [Vcolname, setVcolname] = React.useState('nVarchar 100');
    const [Pcolname, setPcollname] = React.useState('nVarchar 100');
    const [Vcoltype, setVcoltype] = React.useState('nVarchar 100');
    const [Pcoltype, setPcoltype] = React.useState('nVarchar 100');

    return (
        <Box className="field_container" id="scrollbar_style" sx={{ paddingRight: "26px" }}>
            <Box sx={{ width: "100%" }}>
                <Button sx={{ width: '100%' }} variant="contained" onClick={() => setEdit(false)}>Edit</Button>
            </Box>
            <Box className="statements_fields">
                <TextField className="field" value={Vcolname} onChange={(e) => setVcolname(e.target.value)} disabled={edit} id="outlined-basic" variant="outlined" label="Virtual column name" InputLabelProps={{ shrink: true }} />
            </Box>
            <Box className="statements_fields">
                <TextField className="field" value={Pcolname} onChange={(e) => setPcollname(e.target.value)} disabled={edit} id="outlined-basic" variant="outlined" label="Physical column Name" InputLabelProps={{ shrink: true }} />
            </Box>
            <Box className="statements_fields">
                <TextField className="field" value={Vcoltype} onChange={(e) => setVcoltype(e.target.value)} disabled={edit} id="outlined-basic" variant="outlined" label="Virtual column type" InputLabelProps={{ shrink: true }} />
            </Box>
            <Box className="statements_fields">
                <TextField className="field" value={Pcoltype} onChange={(e) => setPcoltype(e.target.value)} disabled={edit} id="outlined-basic" variant="outlined" label="Physical column type" InputLabelProps={{ shrink: true }} />
            </Box>
            <Box className="d-flex align-items-center">
                <CheckBox disabled={edit} /><span>Contains Sensitive Data</span>
            </Box>
            <Box>
                <TextareaAutosize
                    className='script_area'
                    minRows={6}
                    style={{ width: '98%' }}
                    label="Additional Parameters"
                    placeholder='Comments'
                    disabled={edit}

                />
            </Box>
            {
                edit ? ('') : (
                    <Box sx={{ width: "95%", display: 'flex', justifyContent: 'center', alignItems: "center", marginTop: '1rem' }}>
                        <Button sx={{ marginRight: '1rem', width: '40%' }} color="error" variant="contained" onClick={() => setEdit(true)}>Cancel</Button>
                        <Button sx={{ width: '40%' }} variant="contained" color="success" >Update</Button>
                    </Box>
                )
            }
        </Box>
    );
};

export default Email;