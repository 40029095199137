import React, { useState } from "react";
import { Box } from "@mui/system";
import { Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import '../../assets/Styles/Connection/connection.scss';
import ConnectionTable from './DataTable';
const FilterOption = [
  {
    value: 'All',
    label: 'All',
  },
  {
    value: 'Target',
    label: 'Target',
  },
  {
    value: 'Source',
    label: 'Source',
  }

];

const categoryOption = [
  {
    value: 'Acounting',
    label: 'Acounting',
  },
  {
    value: 'Ads',
    label: 'Ads',
  },
  {
    value: 'Big Data',
    label: 'Big Data',
  }

];
const Connection = () => {

  const [filter, setFilter] = useState('All');
  const [category, setCategory] = useState('Ads');
  const [showBar, setShowBar] = useState(false);
  const handleChangeFilter = (event) => {
    setFilter(event.target.value);
  };
  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
  }
  return (
    <Box id="scrollbar_style" className="main_cont">
      <Box class="second_cont">
        <Box className="connection_container">
          <Box >
            <Toolbar style={{ paddingLeft: '0px', paddingTop: '10px', paddingRight: '0px' }}>
              <Box className='table_header_container' >
                <Box
                  component="form"
                  sx={{
                    '& .MuiTextField-root': { m: 1, ml: 0 },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    className="filter_field"
                    select
                    label="Select Filter"
                    value={filter}
                    onChange={handleChangeFilter}
                  >
                    {FilterOption.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    className="category_field"
                    select
                    label="Select Category "
                    value={category}
                    onChange={handleChangeCategory}
                  >
                    {categoryOption.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    className="search_field"
                    variant="outlined"
                    label="Search"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box>
                  <Link variant="outlined" to="/newconnection" style={{ textDecoration: 'none' }}>
                    <Button variant="contained">
                      Add New Connection
                    </Button>
                  </Link>
                </Box>
              </Box>
            </Toolbar>
          </Box>
          <ConnectionTable />
        </Box>
      </Box>
    </Box>
  )
};

export default Connection;