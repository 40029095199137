import React, { useState } from 'react';
import { Box } from "@mui/system";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import CheckBox from '@mui/material/Checkbox';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import '../../../assets/Styles/Connection/connection.scss';

const DialectOptions = [
    {
        value: 'Accelo',
        label: 'Accelo',
    },
    {
        value: 'Access',
        label: 'Access',
    },
    {
        value: 'Act',
        label: 'Act',
    }

];


const ConnectionDetails = () => {

    const [dialect, setDialect] = useState('Accelo');
    const [showString, setShowString] = useState(false)
    const [showAdvanceOption, setShowAdvanceOption] = useState(false);
    const [pooling, setPooling] = useState(false);
    const [other, setOther] = useState(false);
    const [profileSetting, setProfileSetting] = useState(false);

    return (
        <Box sx={{ display: 'flex' }}>

            <Box className="connection_detail_container" id="scrollbar_style" >
                <Box>
                    <TextField
                        className="connection_name_field"
                        id="outlined-basic"
                        variant="outlined" label="Connection Name" />

                </Box>
                <Box>
                    <TextField
                        className="connection_name_field"
                        select

                        label="Select Category"
                        value={dialect}
                        onChange={(e) => setDialect(e.target.value)}
                    >
                        {DialectOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Box>
                <Box className="show_string_container">
                    <CheckBox value={showString} onClick={() => setShowString(!showString)} /> <span> <strong>Show Connection String</strong></span>
                </Box>
                {
                    showString ? (
                        <Box sx={{ width: "96%", margin: '0 auto', marginTop: "25px", marginBottom: '25px' }}>
                            <TextareaAutosize
                                className='script_area'
                                minRows={4}
                                defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                                ut labore et dolore magna aliqua."
                                style={{ width: 200 }}
                            />
                        </Box>
                    ) : ('')
                }
                <Box>
                    <TextField
                        className="connection_name_field"
                        id="outlined-basic"
                        variant="outlined" label="O AuthClientid" />

                </Box>
                <Box>
                    <TextField
                        className="connection_name_field"
                        id="outlined-basic"
                        variant="outlined" label="O AuthClientSecret" />
                </Box>
                <Box className="show_string_container">
                    <CheckBox value={showAdvanceOption} onClick={() => setShowAdvanceOption(!showAdvanceOption)} /> <span> <strong>Generate Log File</strong></span>
                </Box>
                {
                    showAdvanceOption ? (
                        <Box>
                            <Box className="show_string_container">
                                <CheckBox value={pooling} onClick={() => setPooling(!pooling)} /> <span> <strong>Pooling</strong></span>
                            </Box>
                            <Box className="show_string_container">
                                <CheckBox value={other} onClick={() => setOther(!other)} /> <span> <strong>Other</strong></span>
                            </Box>
                            <Box className="show_string_container">
                                <CheckBox value={profileSetting} onClick={() => setProfileSetting(!profileSetting)} /> <span> <strong>Profile Setting</strong></span>
                            </Box>
                        </Box>
                    ) : ('')
                }
                <Box sx={{ width: "96%", margin: '0 auto' }}>
                    <TextareaAutosize
                        className='script_area'
                        minRows={4}
                        style={{ width: 200, border: '1px solid grey' }}
                        placeholder='Initialization SQL'
                    />
                </Box>
                <Box>
                    <Box className="connection_btn">
                        <button>
                            Cancel
                        </button>
                        <button>
                            Test Connection
                        </button>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '50%', background: "#FAFAFA" }} >
                <h2>Connection Detail Guide</h2>
                <p>(Coming Soon)</p>
            </Box>
        </Box>
    )
}
export default ConnectionDetails;