import React from "react";
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { Box } from "@mui/system";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    border: '2px #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    height: 'auto',
};
function createModalPreviewData(name) {
    return {
        name
    };
}
const datapreviewdatarows = [
    createModalPreviewData('2pm everyday'),
    createModalPreviewData('every sunday'),
    createModalPreviewData('some day'),
];
const AddScheduleModal = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box>
            <Box>
                <Button variant="contained" onClick={handleOpen}>Add Schedule</Button>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style }}>
                    Select Schedule
                    <Box>
                        <Box className="panels" sx={{ width: 'max-content', typography: 'body1' }}>
                            <TableContainer component={Paper} sx={{ overflowY: 'auto !important', height: '360px' }} id="scrollbar_style">
                                <Table aria-label="simple table">
                                    <TableHead >
                                        <TableRow>
                                            <TableCell>Name	</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {datapreviewdatarows.map((dtrow) => (
                                            <TableRow
                                                key={dtrow.name}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {dtrow.name}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }} >
                                <Button variant="contained" onClick={handleClose} sx={{ margin: "8px" }} color="error">
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal >
        </Box >
    )
};

export default AddScheduleModal;