import React, { useState } from 'react';
import { Box } from "@mui/system";
import TextField from '@mui/material/TextField';
import CheckBox from '@mui/material/Checkbox';
import '../../../assets/Styles/Connection/connection.scss';



const ViewProviderDetails = () => {

    const [trustConnection, settrustConnection] = useState(false)
    const [encrypt, setencrypt] = useState(false);
    const [pool, setPool] = useState(false);

    return (
        <Box className="provider_detail_container" id="scrollbar_style">
            <Box className="show_string_container">

                <CheckBox value={trustConnection} onClick={() => settrustConnection(!trustConnection)} /> <span> <strong>Is connection trusted</strong></span>

            </Box>
            <Box className="provide_field_section_1">
                <TextField
                    className="connection_name_field"
                    id="outlined-basic"
                    disabled
                    variant="outlined" label="Trusted Connection Seed" />

                <TextField
                    className="connection_name_field"
                    id="outlined-basic"
                    disabled
                    variant="outlined" label="Server Dialect" />
            </Box>

            <Box className="provide_field_section_1">
                <TextField
                    className="connection_name_field"
                    id="outlined-basic"
                    disabled
                    variant="outlined" label="Server Name" />

                <TextField
                    className="connection_name_field"
                    id="outlined-basic"
                    disabled
                    variant="outlined" label="Protocol" />
            </Box>

            <Box className="provide_field_section_1">
                <TextField
                    className="connection_name_field"
                    id="outlined-basic"
                    disabled
                    variant="outlined" label="Port" />

                <TextField
                    className="connection_name_field"
                    id="outlined-basic"
                    disabled
                    variant="outlined" label="Authentication Method" />
            </Box>

            <Box className="provide_field_section_1">
                <TextField
                    className="connection_name_field"
                    id="outlined-basic"
                    disabled
                    variant="outlined" label="Username" />

                <TextField
                    className="connection_name_field"
                    id="outlined-basic"
                    disabled
                    variant="outlined" label="Password" />
            </Box>

            <Box className="show_string_container">

                <CheckBox value={encrypt} onClick={() => setencrypt(!encrypt)} /> <span> <strong>Encrypt</strong></span>

            </Box>

            <Box className="provide_field_section_1">
                <TextField
                    className="connection_name_field"
                    id="outlined-basic"
                    disabled
                    variant="outlined" label="Command Timeout" />

                <TextField
                    className="connection_name_field"
                    id="outlined-basic"
                    disabled
                    variant="outlined" label="Connection TImeout" />
            </Box>

            <Box className="show_string_container">

                <CheckBox value={pool} onClick={() => setPool(!pool)} /> <span> <strong>Pool</strong></span>
            </Box>

            <Box className="provide_field_section_1">
                <TextField
                    className="connection_name_field"
                    id="outlined-basic"
                    disabled
                    variant="outlined" label="min pool size" />

                <TextField
                    className="connection_name_field"
                    id="outlined-basic"
                    disabled
                    variant="outlined" label="max pool size" />
            </Box>
            <Box className="provide_field_section_1">
                <TextField
                    style={{ width: '100%' }}
                    className="connection_name_field"
                    id="outlined-basic"
                    variant="outlined" disabled
                    label="Additional Parameters"
                />
            </Box>
            <Box className="provide_field_section_1">
                <TextField
                    style={{ width: '100%' }}
                    className="connection_name_field"
                    id="outlined-basic"
                    variant="outlined" disabled
                    label="initialization SQL"
                />
            </Box>
            <Box className="provide_field_section_1">
                <TextField
                    style={{ width: '100%' }}
                    className="connection_name_field"
                    id="outlined-basic"
                    variant="outlined" disabled label="BULK Insert Batch Size" />

            </Box>
        </Box>
    )
}
export default ViewProviderDetails;