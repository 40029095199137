import React, { useState } from "react";
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';
import TabPanel from '@mui/lab/TabPanel';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { Checkbox } from '@mui/material';
import Button from '@mui/material/Button';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
function createModalData(privilege, allow, grant, deny) {
    return {
        privilege,
        allow,
        grant,
        deny
    };
}
const datatablerows = [
    createModalData('privilege', <Checkbox className='action_container'></Checkbox>, <Checkbox className='action_container'></Checkbox>, <Checkbox className='action_container'></Checkbox>),
    createModalData('privilege', <Checkbox className='action_container'></Checkbox>, <Checkbox className='action_container'></Checkbox>, <Checkbox className='action_container'></Checkbox>),
    createModalData('privilege', <Checkbox className='action_container'></Checkbox>, <Checkbox className='action_container'></Checkbox>, <Checkbox className='action_container'></Checkbox>),
    createModalData('privilege', <Checkbox className='action_container'></Checkbox>, <Checkbox className='action_container'></Checkbox>, <Checkbox className='action_container'></Checkbox>),
    createModalData('privilege', <Checkbox className='action_container'></Checkbox>, <Checkbox className='action_container'></Checkbox>, <Checkbox className='action_container'></Checkbox>),
    createModalData('privilege', <Checkbox className='action_container'></Checkbox>, <Checkbox className='action_container'></Checkbox>, <Checkbox className='action_container'></Checkbox>),
    createModalData('privilege', <Checkbox className='action_container'></Checkbox>, <Checkbox className='action_container'></Checkbox>, <Checkbox className='action_container'></Checkbox>),
    createModalData('privilege', <Checkbox className='action_container'></Checkbox>, <Checkbox className='action_container'></Checkbox>, <Checkbox className='action_container'></Checkbox>),

];
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    height: 600,
    bgcolor: 'white',
    border: '2px #D3D3D3',
    boxShadow: 24,
    pt: 4,
    px: 4,
    pb: 4,
};

const PermissionModal = () => {
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box>
            <Box className='action_container' sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button onClick={handleOpen}>
                    <RemoveRedEyeIcon />
                </Button>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style }}>
                    <Typography h2 sx={{ textAlign: 'center', fontSize: '20px', fontWeight: '600', paddingBottom: '10px' }}>Access rights: 198</Typography>
                    <Box className="container" value="1" sx={{
                        paddingLeft: '0px', paddingRight: '0px', overflowY: 'auto',
                        height: 500
                    }} id="scrollbar_style">
                        <TableContainer component={Paper} sx={{ overflowY: 'auto !important' }} id="scrollbar_style">
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Privilege</TableCell>
                                        <TableCell align="right">Allow</TableCell>
                                        <TableCell align="right">Grant</TableCell>
                                        <TableCell align="right">Deny</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {datatablerows.map((dtrow) => (
                                        <TableRow
                                            key={dtrow.name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {dtrow.privilege}
                                            </TableCell>
                                            <TableCell align="right">{dtrow.allow}</TableCell>
                                            <TableCell align="right">{dtrow.grant}</TableCell>
                                            <TableCell align="right">{dtrow.deny}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'end', paddingTop: '10px' }}>
                        <Button variant="outlined" onClick={handleClose} size="large" sx={{ width: '135px', height: '40px', marginRight: '10px', textAlign: 'center', marginTop: '10px' }}>
                            Cancel
                        </Button>
                        <Button variant="outlined" size="large" sx={{ width: '135px !important', height: '40px', marginRight: '10px', textAlign: 'center', marginTop: '10px' }}>
                            Revoke All
                        </Button>
                        <Button variant="outlined" size="large" sx={{ width: '135px !important', height: '40px', textAlign: 'center', marginTop: '10px' }}>
                            Save All
                        </Button>
                    </Box>
                </Box>

            </Modal>
        </Box>
    );
}
export default PermissionModal;