import React, { useState } from 'react';
import { Box } from "@mui/system";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import CheckBox from '@mui/material/Checkbox';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import '../../../assets/Styles/Connection/connection.scss';

const DialectOptions = [
    {
        value: 'Accelo',
        label: 'Accelo',
    },
    {
        value: 'Access',
        label: 'Access',
    },
    {
        value: 'Act',
        label: 'Act',
    }

];

const ConnectionDetails = () => {
    const [dialect, setDialect] = useState('Accelo');
    const [showString, setShowString] = useState(false)
    const [showAdvanceOption, setShowAdvanceOption] = useState(false);
    const [pooling, setPooling] = useState(false);
    const [other, setOther] = useState(false);
    const [profileSetting, setProfileSetting] = useState(false);

    return (
        <Box id="scrollbar_style" className="main_cont">
            <Box class="second_cont">
                <Box sx={{ display: 'flex' }}>
                    <Box className="connection_detail_container" >
                        <Box>
                            <TextField
                                className="connection_name_field"
                                id="outlined-basic"
                                variant="outlined" focused label="Connection Name" />


                            <TextField
                                className="connection_name_field"
                                select
                                focused
                                label="Select Category"
                                value={dialect}
                                onChange={(e) => setDialect(e.target.value)}
                            >
                                {DialectOptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                        <Box className="show_string_container">

                            <CheckBox value={showString} onClick={() => setShowString(!showString)} /> <span> <strong>Show Connection String</strong></span>

                        </Box>
                        {
                            showString ? (
                                <Box>
                                    <TextareaAutosize
                                        className='script_area'
                                        minRows={4}
                                        defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                                ut labore et dolore magna aliqua."
                                        style={{ width: 200 }}
                                    />
                                </Box>
                            ) : ('')
                        }

                        <Box>
                            <TextField
                                className="connection_name_field"
                                id="outlined-basic"
                                variant="outlined" focused label="O AuthClientid" />

                        </Box>
                        <Box>
                            <TextField
                                className="connection_name_field"
                                id="outlined-basic"
                                variant="outlined" focused label="O AuthClientSecret" />

                        </Box>
                        <Box className="show_string_container">

                            <CheckBox value={showAdvanceOption} onClick={() => setShowAdvanceOption(!showAdvanceOption)} /> <span> <strong>Show Connection String</strong></span>

                        </Box>
                        {
                            showAdvanceOption ? (
                                <Box>
                                    <Box className="show_string_container">

                                        <CheckBox value={pooling} onClick={() => setPooling(!pooling)} /> <span> <strong>Pooling</strong></span>

                                    </Box>
                                    <Box className="show_string_container">

                                        <CheckBox value={other} onClick={() => setOther(!other)} /> <span> <strong>Other</strong></span>

                                    </Box>
                                    <Box className="show_string_container">

                                        <CheckBox value={profileSetting} onClick={() => setProfileSetting(!profileSetting)} /> <span> <strong>Profile Setting</strong></span>

                                    </Box>

                                </Box>
                            ) : ('')
                        }
                        <Box>
                            <TextareaAutosize
                                className='script_area'
                                minRows={4}
                                style={{ width: 200 }}
                            />
                        </Box>
                        <Box>
                            <Box className="connection_btn">
                                <button>
                                    Cancel
                                </button>
                                <button>
                                    Test Connection
                                </button>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '50%', background: "#FAFAFA" }} >
                        <h2>Connection Detail Guide</h2>
                        <p>(Coming Soon)</p>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
export default ConnectionDetails;