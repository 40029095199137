import React, { useState } from "react";
import { Box } from "@mui/system";
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import '../../assets/Styles/Target/target.scss';
import WorkFlowTable from './DataTable';
import AddWorkFlowModal from "./AddWorkFlowModal";

const categoryOption = [
  {
    value: 'Acounting',
    label: 'Acounting',
  },
  {
    value: 'Ads',
    label: 'Ads',
  },
  {
    value: 'Big Data',
    label: 'Big Data',
  }
];
const WorkFlow = () => {
  const [category, setCategory] = useState('Ads');
  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
  }

  return (
    <Box id="scrollbar_style" className="main_cont">
      <Box class="second_cont">
        <Box className="connection_container">
          <Box >
            <Toolbar style={{ paddingLeft: '0px', paddingTop: '10px', paddingRight: '0px', paddingBottom: '20px' }}>
              <Box className='table_header_container' >
                <Box
                  component="form"
                  sx={{
                    '& .MuiTextField-root': { m: 1, ml: 0 },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    className="search_field"
                    variant="outlined"
                    label="Search"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    className="category_field"
                    select
                    label="Select Category"
                    value={category}
                    onChange={handleChangeCategory}
                  >
                    {categoryOption.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
                <Box>
                  <AddWorkFlowModal />
                </Box>
              </Box>
            </Toolbar>
          </Box >
          <WorkFlowTable />
        </Box >
      </Box >
    </Box >
  )
};

export default WorkFlow;