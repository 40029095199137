import React, { useState } from 'react';
import { Box } from "@mui/system";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import CheckBox from '@mui/material/Checkbox';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import '../../../assets/Styles/Connection/connection.scss';

const DialectOptions = [
  {
    value: 'MySQL',
    label: 'MySQL',
  },
  {
    value: 'MariaDB',
    label: 'MariaDB',
  }

];
const ProtocolOption = [
  {
    value: 'TCP/IP',
    label: 'TCP/IP',
  },
  {
    value: 'NamedPipes',
    label: 'NamedPipes',
  }

];
const AuthMethod = [
  {
    value: 'Username/Password',
    label: 'Username/Password',
  },
  {
    value: 'Windows on authentication method',
    label: 'Windowsonauthenticationmethod',
  }

];

const ConnectionDetails = () => {

  const [dialect, setDialect] = useState('MySQL');
  const [trustConnection, settrustConnection] = useState(false)
  const [encrypt, setencrypt] = useState(false);
  const [pass, setpass] = useState(false);
  const [pool, setPool] = useState(false);

  return (
    <Box className="connection_detail_container" sx={{ width: '100% !important' }} id="scrollbar_style">
      <Box>
        <TextField
          className="connection_name_field"
          id="outlined-basic"
          variant="outlined" label="*Connection Name" />

      </Box>

      <Box className="show_string_container">

        <CheckBox value={trustConnection} onClick={() => settrustConnection(!trustConnection)} /> <span> <strong>is connection trusted</strong></span>

      </Box>
      {
        trustConnection ? (
          <Box>
            <TextField
              className='connection_name_field'
              label="*Trusted connection salt"
            />
          </Box>
        ) : (
          <Box>
            <TextField
              className='connection_name_field'
              label="*Trusted connection salt"
              disabled
            />
          </Box>
        )
      }
      <Box >
        <TextField
          className="connection_name_field"
          select
          label="*Select Category"
          value={dialect}
          onChange={(e) => setDialect(e.target.value)}
        >
          {DialectOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box>
        <TextField
          className='connection_name_field'
          label="*Server Name"
        />
      </Box>
      <Box>
        <TextField
          className="connection_name_field"
          select
          label="*Select Category"
          value={dialect}
          onChange={(e) => setDialect(e.target.value)}
        >
          {ProtocolOption.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box>
        <TextField
          className="connection_name_field"
          id="outlined-basic"
          variant="outlined" label="*Port" />

      </Box>
      <Box>
        <TextField
          className="connection_name_field"
          select
          label="*Select Category"
          value={dialect}
          onChange={(e) => setDialect(e.target.value)}
        >
          {AuthMethod.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box>
        <TextField
          className="connection_name_field"
          id="outlined-basic"
          variant="outlined" label="*Username" />

      </Box>
      <Box className="show_string_container">

        <CheckBox value={pass} onClick={() => setpass(!pass)} /> <span> <strong>Set password</strong></span>

      </Box>
      {
        pass ? (
          <Box>
            <TextField
              className='connection_name_field'
              label="*Password"
            />
          </Box>
        ) : (
          <Box>
            <TextField
              className='connection_name_field'
              label="*Password"
              disabled
            />
          </Box>
        )
      }
      <Box className="show_string_container">

        <CheckBox value={encrypt} onClick={() => setencrypt(!encrypt)} /> <span> <strong>Encrypt</strong></span>

      </Box>
      <Box>
        <TextField
          className="connection_name_field"
          id="outlined-basic"
          variant="outlined" label="*Connection Timeout" />

      </Box>
      <Box>
        <TextField
          className="connection_name_field"
          id="outlined-basic"
          variant="outlined" label="*Command Timeout" />

      </Box>

      <Box className="show_string_container">

        <CheckBox value={pool} onClick={() => setPool(!pool)} /> <span> <strong>Pool</strong></span>

      </Box>
      <Box>
        <TextField
          className="connection_name_field"
          id="outlined-basic"
          variant="outlined" label="*Min Pool Size" />

      </Box>
      <Box>
        <TextField
          className="connection_name_field"
          id="outlined-basic"
          variant="outlined" label="*Max Pool Size" />

      </Box>
      <Box sx={{ margin: '0 auto', width: '96%' }}>
        <TextareaAutosize
          className='script_area'
          minRows={4}
          style={{ width: '90%' }}
          label="*Additional Parameters"
        />
      </Box>
      <Box sx={{ margin: '0 auto', width: '96%' }}>
        <TextareaAutosize
          className='script_area'
          minRows={4}
          style={{ width: '90%' }}
          label="*Initialization SQL"
          defaultValue="SET @@SQL_MODE=''"
        />
      </Box>
      <Box>
        <TextField
          className="connection_name_field"
          id="outlined-basic"
          variant="outlined" label="*BULK Insert Batch Size" />

      </Box>
      <Box>
        <Box className="connection_btn">
          <button>
            Cancel
          </button>
          <button>
            Test Connection
          </button>
          <button>
            Save
          </button>
        </Box>
      </Box>
    </Box>
  )
}
export default ConnectionDetails;