import React, { useState } from 'react';
import { Box } from "@mui/system";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import '../../../assets/Styles/Connection/connection.scss';
import Header from '../../../Components/Layout/Navbar';
import SideBar from '../../../Components/Layout/SideBar';
import ProviderDetails from '../../../Components/ConnectionComponent/ViewConnection/ViewProviderDetails';
import AccessRights from '../../../Components/ConnectionComponent/ViewConnection/AccessRights';
import DataSources from '../../../Components/ConnectionComponent/ViewConnection/DependentDataSource';
import { TextField } from '@mui/material';
const NewConnection = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState('1');
  const [Dialect, setDialect] = useState('MySQL');
  const [connection, setConnection] = useState('invo_DB');
  const [dataNode, setDatanode] = useState('Any node');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Header />
      <Box className="dashboard_container">
        <SideBar />
        <Box id="scrollbar_style" className="main_cont">
            <Box class="second_cont">
            <Box className="new_connection_container">
              <Box className="page_title">
                <h3>VIEW CONNECTION</h3>
                <ArrowBackIcon onClick={() => navigate(-1)} />
              </Box>
              <Grid container >
                <Grid item xs={3} style={{ width: '100%', height: '100vh' }}>
                  <Box className="basic_connection_info">
                    <TextField className="basic_info_fields" variant="outlined" value={Dialect} label="Dialect" focused disabled />
                    <TextField className="basic_info_fields" variant="outlined" value={connection} label="Connection" focused disabled />
                    <TextField className="basic_info_fields" variant="outlined" value={dataNode} label="Lyftron Data node" focused disabled />
                  </Box>
                </Grid>
                <Grid item xs={9} style={{ width: '100%', height: '100vh', background: '#FAFAFA' }}>
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' , width: '98%' }}>
                      <TabList onChange={handleChange} aria-label="lab API tabs example " style={{  background: 'white' }}>
                        <Tab label="Provider Details" value="1" />
                        <Tab label="Access Rights" value="2" />
                        <Tab label="Dependent Data Sources" value="3" />
                      </TabList>
                    </Box>
                    <TabPanel className="container" style={{ padding: '0px', width: '100%', height: '100%' }} value="1">
                      <ProviderDetails />
                    </TabPanel>
                    <TabPanel className="container" style={{ padding: '0px', height: '100%' }} value="2">
                      <AccessRights />
                    </TabPanel>
                    <TabPanel className="container" style={{ padding: '0px', width: '95%', height: '100%' }} value="3">
                      <DataSources />
                    </TabPanel>
                  </TabContext>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>


  )


}
export default NewConnection; 