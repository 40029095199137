import * as React from 'react';
import Box from '@mui/material/Box';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import '../../../SourceComponent/source.scss'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    border: '2px #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};
const ViewDetailModal = () => {
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    function createModalData(parentcolumn, referencedcolumn) {
        return {
            parentcolumn,
            referencedcolumn
        };
    }
    const datatablerows = [
        createModalData('salesRepEmployeeNumber', 'employeeNumber'),
        createModalData('salesRepEmployeeNumber', 'employeeNumber'),
        createModalData('salesRepEmployeeNumber', 'employeeNumber'),
        createModalData('salesRepEmployeeNumber', 'employeeNumber'),
        createModalData('salesRepEmployeeNumber', 'employeeNumber'),
        createModalData('salesRepEmployeeNumber', 'employeeNumber'),
    ];
    return (
        <Box className='action_container' sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={handleOpen}>
                <RemoveRedEyeIcon />
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style }}>
                    <Box>
                        <Box className="panels" sx={{ width: 'max-content', typography: 'body1' }}>
                            <TabContext isVertical value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList isVertical onChange={handleChange} aria-label="lab API tabs example " style={{ background: 'white' }}>
                                        <Tab label="Summary" value="1" />
                                        <Tab label="Column mappings" value="2" />
                                    </TabList>
                                </Box>
                                <TabPanel className="container" value="1" >
                                    <Box className="field_container" sx={{ overflow: 'auto !important', height: 'auto' }} id="scrollbar_style">
                                        <Box className="statements_fields">
                                            <TextField className="field" value="customers_ibfk_1" disabled id="outlined-basic" variant="outlined" label="Foriegn key name" InputLabelProps={{ shrink: true }} />
                                        </Box>
                                        <Box className="statements_fields">
                                            <TextField className="field" value="invo_database.classicmodels.customers" disabled id="outlined-basic" variant="outlined" label="Parent table" InputLabelProps={{ shrink: true }} />
                                        </Box>
                                        <Box className="statements_fields">
                                            <TextField className="field" value="invo_database.classicmodels.employees" disabled id="outlined-basic" variant="outlined" label="Referenced table" InputLabelProps={{ shrink: true }} />
                                        </Box>
                                    </Box>
                                </TabPanel>
                                <TabPanel className="container" value="2">
                                    <TableContainer component={Paper} sx={{ overflowY: 'auto !important', height: '360px' }} id="scrollbar_style">
                                        <Table aria-label="simple table" sx={{ minWidth: 400 }} >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Parent column	</TableCell>
                                                    <TableCell align="right">Referenced column</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {datatablerows.map((dtrow) => (
                                                    <TableRow
                                                        key={dtrow.name}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {dtrow.parentcolumn}
                                                        </TableCell>
                                                        <TableCell align="right">{dtrow.referencedcolumn}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </TabPanel>
                            </TabContext>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </Box>

    );
};

export default ViewDetailModal;