import React from "react";
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { Box } from '@mui/system';
import PermissionModal from "./PermissionModal";
import Grid from '@mui/material/Grid';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    height: 600,
    bgcolor: 'white',
    border: '2px #D3D3D3',
    boxShadow: 24,
    pt: 4,
    px: 4,
    pb: 4,
};
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
function createData(columnname, eyemodal) {
    return {
        columnname,
        eyemodal
    };
}
const rows = [
    createData('test@gmail.com', <Box className='action_container'></Box>),
    createData('test@gmail.com', <Box className='action_container'></Box>),
    createData('test@gmail.com', <Box className='action_container'></Box>),
    createData('test@gmail.com', <Box className='action_container'></Box>),
    createData('test@gmail.com', <Box className='action_container'></Box>),
    createData('test@gmail.com', <Box className='action_container'></Box>),
    createData('test@gmail.com', <Box className='action_container'></Box>),
    createData('test@gmail.com', <Box className='action_container'></Box>),
    createData('test@gmail.com', <Box className='action_container'></Box>),
    createData('test@gmail.com', <Box className='action_container'></Box>),
    createData('test@gmail.com', <Box className='action_container'></Box>),
    createData('test@gmail.com', <Box className='action_container'></Box>),

];

const SelectUserModal = () => {
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (

        <Box sx={{ marginRight: '10px' }}>

            <Box sx={{ width: "100%", display: 'flex', justifyContent: 'flex-end', alignItems: "center", marginTop: '1rem' }}>
                <Button onClick={handleOpen} variant="contained">Select User</Button>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style }}>
                    <Box>
                        <Box role="presentation" onClick={handleClick} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Breadcrumbs aria-label="breadcrumb" sx={{ textAlign: 'center', fontSize: '20px', fontWeight: '600', paddingBottom: '10px' }}>
                                <Link underline="hover" color="inherit" href="/viewsourcenested">
                                    Select User
                                </Link>
                                <Link
                                    underline="hover"
                                    color="inherit"
                                    href="/getting-started/installation/"
                                > Access Rights: 198
                                </Link>
                            </Breadcrumbs>
                        </Box>
                        <Box className="container" value="1" sx={{
                            paddingLeft: '0px', paddingRight: '0px', overflowY: 'auto',
                            height: 500
                        }} id="scrollbar_style">
                            <Grid container spacing={2} sx={{ width: '95%', margin: '0 auto' }}>
                                <Grid item xs={12} sx={{ paddingTop: '20px', paddingBottom: '20px', paddingLeft: '0px !important' }} >
                                    <TableContainer component={Paper} id="scrollbar_style">
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left"><b>User</b></TableCell>
                                                    <TableCell align="center"><b>Action</b></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows.map((row) => (
                                                    <TableRow
                                                        key={row.name}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell align="left">{row.columnname}</TableCell>
                                                        <TableCell align="center"><PermissionModal /></TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{ paddingTop: '6px', display: 'flex', justifyContent: 'end' }}>
                            <Button variant="outlined" onClick={handleClose} size="large" sx={{ marginRight: '10px', width: '100px', textAlign: 'center', marginTop: '10px' }}>
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}
export default SelectUserModal;