import React, { useState } from "react";
import { Box } from "@mui/system";
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Modal from '@mui/material/Modal';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import DataTable from './DataTable';
import AssignTable from "./AssignDataTable";



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'white',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  overflow: 'scroll'
};



const Tags = () => {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box className="connection_container" sx={{ width: '100%' }} >
      <Box >
        <Toolbar style={{ paddingLeft: '13px', paddingTop: '20px', marginBottom: '20px' }}>
          <Box className='table_header_container' >
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, ml: 0 },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                className="search_field"
                variant="outlined"
                label="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />


            </Box>

            <Button variant="contained" onClick={handleOpen}>Assign</Button>
          </Box>
        </Toolbar>
      </Box>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={{ ...style }}>
            <AssignTable />
          </Box>
        </Modal>
      </div>
      <DataTable />
    </Box>
  )
};

export default Tags;