import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';
import EditWorkFlowModal from './EditWorkFlowModal';
function createData(name, enabled, ownername, createdat, lastexecutedat, status, action) {
  return {
    name,
    enabled,
    ownername,
    createdat,
    lastexecutedat,
    status,
    action,
  };
}
const rows = [
  createData('name 1', 'yes', 'user-1', '23/12/21', '03/06/2022', 'success',
    <Box className='action_container' sx={{ display: 'flex' }}>
      <Link to="/viewworkflow"><RemoveRedEyeIcon /></Link>
      <EditWorkFlowModal />
      <DeleteIcon />
    </Box>),
  createData('name 2', 'yes', 'user-2', '23/12/21', '03/06/2022', '',
    <Box className='action_container' sx={{ display: 'flex' }}>
      <Link to="/viewworkflow"><RemoveRedEyeIcon /></Link>
      <EditWorkFlowModal />
      <DeleteIcon />
    </Box>
  ),
  createData('name 3', 'yes', 'user-3', '23/12/21', '03/06/2022', 'success',
    <Box className='action_container' sx={{ display: 'flex' }}>
      <Link to="/viewworkflow"><RemoveRedEyeIcon /></Link>
      <EditWorkFlowModal />
      <DeleteIcon />
    </Box>),
  createData('name 4', 'yes', 'user-4', '23/12/21', '03/06/2022', '',
    <Box className='action_container' sx={{ display: 'flex' }}>
      <Link to="/viewworkflow"><RemoveRedEyeIcon /></Link>
      <EditWorkFlowModal />
      <DeleteIcon />
    </Box>),
  createData('name 5', 'yes', 'user-5', '23/12/21', '03/06/2022', 'success',
    <Box className='action_container' sx={{ display: 'flex' }}>
      <Link to="/viewworkflow"><RemoveRedEyeIcon /></Link>
      <EditWorkFlowModal />
    </Box>),
  createData('name 6', 'yes', 'user-6', '23/12/21', '03/06/2022', 'success',
    <Box className='action_container' sx={{ display: 'flex' }}>
      <Link to="/viewworkflow"><RemoveRedEyeIcon /></Link>
      <EditWorkFlowModal />
      <DeleteIcon />
    </Box>),
  createData('name 7', 'yes', 'user-7', '23/12/21', '03/06/2022', '',
    <Box className='action_container' sx={{ display: 'flex' }}>
      <Link to="/viewworkflow"><RemoveRedEyeIcon /></Link>
      <EditWorkFlowModal />
      <DeleteIcon />
    </Box>),
  createData('name 8', 'yes', 'user-8', '23/12/21', '03/06/2022', '',
    <Box className='action_container' sx={{ display: 'flex' }}>
      <Link to="/viewworkflow"><RemoveRedEyeIcon /></Link>
      <EditWorkFlowModal />
      <DeleteIcon />
    </Box>),
  createData('name 9', 'yes', 'user-9', '23/12/21', '03/06/2022', 'success',
    <Box className='action_container' sx={{ display: 'flex' }}>
      <Link to="/viewworkflow"><RemoveRedEyeIcon /></Link>
      <EditWorkFlowModal />
      <DeleteIcon />
    </Box>),
  createData('name 10', 'yes', 'user-10', '23/12/21', '03/06/2022', '',
    <Box className='action_container' sx={{ display: 'flex' }}>
      <Link to="/viewworkflow"><RemoveRedEyeIcon /></Link>
      <EditWorkFlowModal />
      <DeleteIcon />
    </Box>),
  createData('name 11', 'yes', 'user-11', '23/12/21', '03/06/2022', 'success',
    <Box className='action_container' sx={{ display: 'flex' }}>
      <Link to="/viewworkflow"><RemoveRedEyeIcon /></Link>
      <EditWorkFlowModal />
      <DeleteIcon />
    </Box>),
  createData('name 12', 'yes', 'user-12', '23/12/21', '03/06/2022', 'success',
    <Box className='action_container' sx={{ display: 'flex' }}>
      <Link to="/viewworkflow"><RemoveRedEyeIcon /></Link>
      <EditWorkFlowModal />
      <DeleteIcon />
    </Box>),
  createData('name 13', 'yes', 'user-13', '23/12/21', '03/06/2022', 'success',
    <Box className='action_container d-flex' sx={{ display: 'flex' }}>
      <Link to="/viewworkflow"><RemoveRedEyeIcon /></Link>
      <EditWorkFlowModal />
      <DeleteIcon />
    </Box>),
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name'
  },
  {
    id: 'enabled',
    numeric: false,
    disablePadding: true,
    label: 'Enabled',
  },
  {
    id: 'ownername',
    numeric: false,
    disablePadding: false,
    label: 'Owner Name',
  },
  {
    id: 'createdat',
    numeric: true,
    disablePadding: false,
    label: 'Created at',
  },
  {
    id: 'lastexecutedat',
    numeric: true,
    disablePadding: false,
    label: 'Last executed by'
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status'
  },
  {
    id: 'action',
    numeric: true,
    disablePadding: false,
    label: 'Action'
  }
];
function EnhancedTableHead(props) {
  const { order, orderBy, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'left' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ paddingLeft: '12px', fontWeight: '600', fontSize: '16px' }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {

  return (
    <Box>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
      </Toolbar>
    </Box>
  );
};
export default function EnhancedTable() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('category');
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableContainer id="scrollbar_style" sx={{ height: 400, overflowY: 'auto' }}>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.name}
                      >
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                        >
                          {row.name}
                        </TableCell>
                        <TableCell align="left">{row.enabled}</TableCell>
                        <TableCell align="left">{row.ownername}</TableCell>
                        <TableCell align="left">{row.createdat}</TableCell>
                        <TableCell align="left">{row.lastexecutedat}</TableCell>
                        <TableCell align="left">{row.status}</TableCell>
                        <TableCell align="left">{row.action}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
      </Box>
    </Box>
  );
}