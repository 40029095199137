import { Box } from "@mui/material";
import React from "react";
import TextField from '@mui/material/TextField';
import '../../assets/Styles/Catalog/catalog.scss';

const Catalog = () => {
  return (
    <Box id="scrollbar_style" className="RightBar">
      <Box className="rightbar_fields">
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '21ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="outlined-read-only-input"
            label="Virtual Table Name"
            defaultValue="Hello World"
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            id="outlined-read-only-input2"
            label="Full Table Name"
            defaultValue="Hello World"
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            id="outlined-read-only-input3"
            label="Physical Table Name"
            defaultValue="Hello World"
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            id="outlined-read-only-input4"
            label="Filter"
            defaultValue="Hello World"
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default Catalog;