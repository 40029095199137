
import { Box } from "@mui/system";
import React from "react";
import StatCards from './statCards'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import InterfaceTable from './interfaceTable';
import { Typography } from "@mui/material";
import './dashboard.scss'

const dashboard = () => {
    return (
        <Box id="scrollbar_style" className="main_cont">
            <Box class="second_cont">
                <Box className="dashboard_content_container">
                    <Box className="card_box">
                        <Box className="dashboard_header">
                            <Typography>Quick Actions</Typography>
                        </Box>
                        <Box className="cards_container">
                            <StatCards title="Data Sources" subtitle="registered" data="213" />
                            <StatCards title="Connections" subtitle="to data sources" data="312" />
                            <StatCards title="Registered Tables" subtitle="in all data sources" data="22" />
                            <StatCards title="Pipeline(s)" subtitle="created" data="12" />
                            <StatCards title="Active" subtitle="client connection(s)" data="8" />
                            <StatCards title="Workflow(s)" subtitle="currently running" data="145" />
                        </Box>
                    </Box>
                    <Box className="Quick_actions">
                        <Box className="dashboard_header">
                            <Typography>Quick Actions</Typography>
                        </Box>
                        <Box className="quick_links">
                            <p> EXECUTE A QUERY</p>
                            <ArrowForwardIcon />
                        </Box>
                        <Box className="quick_links">
                            <p> ADD SOURCE CONNECTION</p>
                            <ArrowForwardIcon />
                        </Box>
                        <Box className="quick_links">
                            <p>ADD SOURCE INITIAL CATALOG</p>
                            <ArrowForwardIcon />
                        </Box>
                        <Box className="quick_links">
                            <p>ADD USER</p>
                            <ArrowForwardIcon />
                        </Box>
                        <Box className="quick_links">
                            <p> COPY INVODATA DATA SOURCE SERVER NAME TO CLIPBOARD</p>
                            <ArrowForwardIcon />
                        </Box>

                    </Box>
                </Box>
                <Box className="interface_section">
                    <Box className="interface">
                        <Box className="dashboard_header">
                            <Typography>Quick Actions</Typography>
                        </Box>
                        <InterfaceTable />
                    </Box>
                    <Box className="interface">
                        <Box className="dashboard_header">
                            <Typography>Quick Actions</Typography>
                        </Box>
                        <Box className="connect_info_container">
                            <Typography tag="p">Lyftron listens for incoming connections at the server name and port:</Typography>
                            <h4>Trial.lyftron.com,1433</h4>
                            <Typography>For more information about how to connect your tool to lyftron, please consult the user manual</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
export default dashboard;