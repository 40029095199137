import { Box } from "@mui/material";
import React, { useState } from "react";
import Header from '../Components/Layout/Navbar'
import SideBar from '../Components/Layout/SideBar'
import CodeEditor from "../Components/Analyze/CodeEditor";
import NodeBar from "../Components/Analyze/DBnodes";
import '../assets/Styles/Analyze/analyze.scss';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import QueryResultTable from '../Components/Analyze/QueryResult';
import '../Pages/page.scss';
const filterOptions = [
  {
    value: 'Acounting',
    label: 'Acounting',
  },
  {
    value: 'Ads',
    label: 'Ads',
  },
  {
    value: 'Big Data',
    label: 'Big Data',
  }

];

const Analyze = () => {
  const [DBfilter, setDBfilter] = useState('Ads');
  const handleChangeCategory = (event) => {
    setDBfilter(event.target.value);
  }
  return (
    <Box>
      <Header />
      <Box className="dashboard_container">
        <SideBar />
        <Box id="scrollbar_style" className="main_cont">
          <Box class="second_cont">
            <Box className="analyze_main_container">
              <Box className="Node_bar" id="scrollbar_style">
                <Box className="nodebar_header">
                  <h3>Data Source</h3>
                </Box>
                <NodeBar />
              </Box>
              <Box className="query_edito" >
                <Box className="nodebar_header">
                  <h3>Query Editor</h3>
                </Box>
                <Box className='code_container_filter' >
                  <Box
                    component="form"
                    sx={{
                      '& .MuiTextField-root': { m: 1, ml: 0 },
                    }}
                    noValidate
                    autoComplete="off"
                  >

                    <TextField
                      className="category_field_P"
                      select
                      label="Select DataBase"
                      value={DBfilter}
                      onChange={handleChangeCategory}
                    >
                      {filterOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>

                  </Box>
                </Box>
                <CodeEditor />
                <Box className="code_container_buttons">
                  <Button className="btn" variant="contained">Execute</Button>
                  <Button className="btn" variant="outlined">Cancel</Button>
                  <Button className="btn" variant="outlined">History</Button>
                </Box>
              </Box>
            </Box>
            <QueryResultTable />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Analyze;