import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  height: 600,
  bgcolor: 'white',
  border: '2px #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
function createData(columnname, distinctcount, averagelengthbytes, calculatestatistics, crossicon) {
  return {
    columnname,
    distinctcount,
    averagelengthbytes,
    calculatestatistics,
    crossicon
  };
}
export default function PrimaryModal(props) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('1');
  const [formValues, setFormValues] = React.useState([{ email: "" }]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (i, e) => {
    formValues[i][e.target.name] = e.target.value;
    setFormValues([...formValues]);
  }
  const addNew = () => {
    setFormValues([...formValues, { email: "" }])
  }
  const handleSubmitForm = (event) => {
    event.preventDefault();
    alert(JSON.stringify(formValues));
  }
  const removeFormFields = (i) => {
    console.log('formmmmm', formValues, 'index', i)
    formValues.splice(i, 1)
    console.log('before setiing', formValues)
    setFormValues([...formValues])
    console.log('after setiing', formValues)
    // forceUpdateFunction();
  }
  console.log(formValues);
  return (
    <Box>
      <Button variant="contained" sx={{ marginBottom: "10px" }} onClick={handleOpen}>
        Edit
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style }}>
          <Box>
            <Box className="panels" sx={{ width: 'max-content', typography: 'body1' }}>
              <TabContext isVertical value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Typography sx={{ margin: '14px', fontSize: '20px', fontWeight: 600, textAlign: 'center' }}>Edit primary key</Typography>
                </Box>
                <TabPanel className="container" value="1" sx={{
                  paddingLeft: '0px', paddingRight: '0px', overflowY: 'auto',
                  height: 440, paddingTop: '8px'
                }} id="scrollbar_style">
                  <Grid container spacing={2} sx={{ width: '95%', margin: '0 auto' }}>
                    <Grid item xs={12} sx={{ paddingTop: '20px', paddingBottom: '20px', paddingLeft: '0px !important' }} >
                      <TableContainer component={Paper} sx={{ maxHeight: 350 }} id="scrollbar_style">
                        <form onSubmit={handleSubmitForm}>
                          <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell align="center"><b>Position</b></TableCell>
                                <TableCell align="center"><b>Column</b></TableCell>
                                <TableCell align="center"><b>Action</b></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {formValues.map((element, index) => (
                                <TableRow
                                  key={index}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                  <TableCell align="center">{index}</TableCell>
                                  <TableCell align="center">
                                    <TextField
                                      id="outlined-basic" label="Outlined" variant="outlined"
                                      type="email"
                                      name="email"
                                      value={element.email}
                                      placeholder="Please Enter Email"
                                      required
                                      onChange={(e) => handleChange(index, e)}
                                      sx={{ width: "100%" }}
                                    ></TextField>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box className="cross_btn cursor-pointer">
                                      {index ? (
                                        <Button style={{ width: "10px" }} onClick={() => removeFormFields(index)} >x</Button>
                                      ) : null}
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </form>
                      </TableContainer>
                    </Grid>
                    <Grid item xs={12} alignItems="center" justifyContent="center">
                      <Button variant="contained" className="add_more w-25" onClick={() => addNew()} sx={{ textAlign: 'center' }}>
                        Add More
                      </Button>
                    </Grid>
                  </Grid>
                </TabPanel>
                <Box sx={{ borderTop: 1, borderColor: 'divider', display: 'flex', justifyContent: 'end' }}>
                  <Button variant="outlined" size="large" onClick={handleClose} sx={{ marginRight: '10px', width: '100px', marginTop: '10px' }}>
                    Cancel
                  </Button>
                  <Button variant="contained" size="large" sx={{ width: '100px', marginTop: '10px' }}>
                    Save
                  </Button>
                </Box>
              </TabContext>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}