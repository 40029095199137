import * as React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import '../../../../assets/Styles/Monitor/monitor.scss';

function createData(sql, username, executedat, status, action) {
    return {
        sql,
        username,
        executedat,
        status,
        action
    };
}
const rows = [
    createData('some sql', 'user-1', '23/12/21', 'Fail',
        <Box className='action_container'>
        </Box>),
    createData('some sql 2', 'user-2', '23/12/21', 'Success',
        <Box className='action_container'>
            <Link to="/viewconnection"><RemoveRedEyeIcon /></Link>
        </Box>
    ),
    createData('some sql 3', 'user-3', '23/12/21', 'Fail',
        <Box className='action_container'>
            <Link to="/viewconnection"><RemoveRedEyeIcon /></Link>
        </Box>),
    createData('some sql 4', 'user-4', '23/12/21', 'Fail',
        <Box className='action_container'>
            <Link to="/viewconnection"><RemoveRedEyeIcon /></Link>
        </Box>),
    createData('some sql 5', 'user-5', '23/12/21', 'Success',
        <Box className='action_container'>
            <Link to="/viewconnection"><RemoveRedEyeIcon /></Link>
        </Box>),
    createData('some sql 6', 'user-6', '23/12/21', 'Fail',
        <Box className='action_container'>
            <Link to="/viewconnection"><RemoveRedEyeIcon /></Link>
        </Box>),
    createData('some sql 7', 'user-7', '23/12/21', 'Fail',
        <Box className='action_container'>
            <Link to="/viewconnection"><RemoveRedEyeIcon /></Link>
        </Box>),
    createData('some sql 8', 'user-8', '23/12/21', 'Fail',
        <Box className='action_container'>
            <Link to="/viewconnection"><RemoveRedEyeIcon /></Link>
        </Box>),
    createData('some sql 9', 'user-9', '23/12/21', 'Fail',
        <Box className='action_container'>
            <Link to="/viewconnection"><RemoveRedEyeIcon /></Link>
        </Box>),
    createData('some sql 10', 'user-10', '23/12/21', 'Fail',
        <Box className='action_container'>
            <Link to="/viewconnection"><RemoveRedEyeIcon /></Link>
        </Box>),
    createData('some sql 11', 'user-11', '23/12/21', 'Fail',
        <Box className='action_container'>
            <Link to="/viewconnection"><RemoveRedEyeIcon /></Link>
        </Box>),
    createData('some sql 12', 'user-12', '23/12/21', 'Fail',
        <Box className='action_container'>
            <Link to="/viewconnection"><RemoveRedEyeIcon /></Link>
        </Box>),
    createData('some sql 13', 'user-13', '23/12/21', 'Fail',
        <Box className='action_container'>
            <Link to="/viewconnection"><RemoveRedEyeIcon /></Link>
        </Box>),
];
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

const DetailModal = () => {
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <Box>
            <Box className='action_container' sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button onClick={handleOpen}>
                    <RemoveRedEyeIcon />
                </Button>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style }}>
                    <Box>
                        <Box className="panels" sx={{ width: 'max-content', typography: 'body1' }}>
                            <TabContext isVertical value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList isVertical onChange={handleChange} aria-label="lab API tabs example " style={{ background: 'white' }}>
                                        <Tab label="Statement Details" value="1" />
                                        <Tab label="def" value="2" />
                                    </TabList>
                                </Box>
                                <TabPanel className="container" value="1">
                                    <Box className="field_container">
                                        <Box className="statements_fields">
                                            <TextField className="field" value="harib.siddique@invozone.com" disabled id="outlined-basic" variant="outlined" label="Login" InputLabelProps={{ shrink: true }} />
                                        </Box>
                                        <Box className="statements_fields">
                                            <TextField className="field" value="19/20/2022" disabled id="outlined-basic" variant="outlined" label="Date" InputLabelProps={{ shrink: true }} />
                                        </Box>
                                        <Box className="statements_fields">
                                            <TextField className="field" value="Success" disabled id="outlined-basic" variant="outlined" label="Status" InputLabelProps={{ shrink: true }} />
                                        </Box>
                                        <Box className="statements_fields">
                                            <TextField className="field" value="5s" disabled id="outlined-basic" variant="outlined" label="Execution Duration" InputLabelProps={{ shrink: true }} />
                                        </Box>
                                        <Box className="statements_fields">
                                            <TextField className="field" value="Select * from accounts" disabled id="outlined-basic" variant="outlined" label="SQL" InputLabelProps={{ shrink: true }} />
                                        </Box>
                                    </Box>
                                </TabPanel>
                                <TabPanel className="container" value="2">

                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Dessert (100g serving)</TableCell>
                                                    <TableCell align="left">Calories</TableCell>
                                                    <TableCell align="left ">Fat&nbsp;(g)</TableCell>
                                                    <TableCell align="left ">Carbs&nbsp;(g)</TableCell>
                                                    <TableCell align="left ">Protein&nbsp;(g)</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows.map((row) => (
                                                    <TableRow
                                                        key={row.name}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {row.name}
                                                        </TableCell>
                                                        <TableCell align="left ">{row.calories}</TableCell>
                                                        <TableCell align="left ">{row.fat}</TableCell>
                                                        <TableCell align="left ">{row.carbs}</TableCell>
                                                        <TableCell align="left ">{row.protein}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </TabPanel>
                            </TabContext>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
};

export default DetailModal;