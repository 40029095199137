import React, { useState } from 'react';
import { Box } from "@mui/system";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import '../../../assets/Styles/Connection/connection.scss';

const SelectionNode = [
    {
        value: 'Any',
        label: 'Any Invodata Node',
    },
    {
        value: 'SelectedNode',
        label: 'Selected Node',
    }
    ,
    {
        value: 'SelectedNetwork',
        label: 'Selected Network',
    }

];


const InodataNode = () => {

    const [Node, setNode] = useState('Any');


    return (
        <Box className="connection_detail_container" sx={{ width: '100% !important' }} id="scrollbar_style" >

            <Box>
                <TextField
                    className="connection_name_field"
                    select
                    label="Select Category"
                    value={Node}
                    onChange={(e) => setNode(e.target.value)}
                >
                    {SelectionNode.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>

                {
                    Node === "Any" ? ('') : (
                        <Box>
                            <TextField
                                className="connection_name_field"
                                id="outlined-basic"
                                variant="outlined" label={Node === 'SelectedNode' ? 'Select Instance' : "Select Network"} />

                        </Box>
                    )
                }
            </Box>


            <Box>
                <Box className="connection_btn">
                    <button>
                        Cancel
                    </button>
                    <button>
                        Save
                    </button>
                </Box>
            </Box>
        </Box>
    )
}
export default InodataNode;