import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import test from '../../assets/media/88.png';
import { visuallyHidden } from '@mui/utils';

function createData(provider, name, connection, category, createdby, createdat, modifiedby, modifiedat, actions) {
  return {
    provider,
    name,
    connection,
    category,
    createdby,
    createdat,
    modifiedby,
    modifiedat,
    actions,
  };
}

const rows = [
  createData(<img style={{ height: '25px' }} src={test} alt="" />, 'src_name_1', 'connection_1', 'cloud storage', 'user-1', '23/12/21', 'm-1', '03/06/2022',
    <Box className='action_container'>
      <Link to="/viewsource"><RemoveRedEyeIcon /></Link>
      <Link to="/editconnection"><EditIcon /></Link>

    </Box>),
  createData(<img style={{ height: '25px' }} src={test} alt="" />, 'src_name_2', 'connection_2', 'marketing', 'user-2', '23/12/21', 'm-1', '03/06/2022',
    <Box className='action_container'>
      <Link to="/viewsource"><RemoveRedEyeIcon /></Link>
      <Link to="/editconnection"><EditIcon /></Link>
    </Box>
  ),
  createData(<img style={{ height: '25px' }} src={test} alt="" />, 'src_name_3', 'connection_3', 'business', 'user-3', '23/12/21', 'm-1', '03/06/2022',
    <Box className='action_container'>
      <Link to="/viewsource"><RemoveRedEyeIcon /></Link>
      <Link to="/editconnection"><EditIcon /></Link>
    </Box>),
  createData(<img style={{ height: '25px' }} src={test} alt="" />, 'src_name_4', 'connection_4', 'cloud storage', 'user-4', '23/12/21', 'm-1', '03/06/2022',
    <Box className='action_container'>
      <Link to="/viewsource"><RemoveRedEyeIcon /></Link>
      <Link to="/editconnection"><EditIcon /></Link>
    </Box>),
  createData(<img style={{ height: '25px' }} src={test} alt="" />, 'src_name_5', 'connection_5', 'busimess', 'user-5', '23/12/21', 'm-1', '03/06/2022',
    <Box className='action_container'>
      <Link to="/viewsource"><RemoveRedEyeIcon /></Link>
      <Link to="/editconnection"><EditIcon /></Link>
    </Box>),
  createData(<img style={{ height: '25px' }} src={test} alt="" />, 'src_name_6', 'connection_6', 'marketing', 'user-6', '23/12/21', 'm-1', '03/06/2022',
    <Box className='action_container'>
      <Link to="/viewsource"><RemoveRedEyeIcon /></Link>
      <Link to="/editconnection"><EditIcon /></Link>
    </Box>),
  createData(<img style={{ height: '25px' }} src={test} alt="" />, 'src_name_7', 'connection_7', 'media', 'user-7', '23/12/21', 'm-1', '03/06/2022',
    <Box className='action_container'>
      <Link to="/viewsource"><RemoveRedEyeIcon /></Link>
      <Link to="/editconnection"><EditIcon /></Link>
    </Box>),
  createData(<img style={{ height: '25px' }} src={test} alt="" />, 'src_name_8', 'connection_8', 'tech', 'user-8', '23/12/21', 'm-1', '03/06/2022',
    <Box className='action_container'>
      <Link to="/viewsource"><RemoveRedEyeIcon /></Link>
      <Link to="/editconnection"><EditIcon /></Link>
    </Box>),
  createData(<img style={{ height: '25px' }} src={test} alt="" />, 'src_name_9', 'connection_9', 'development', 'user-9', '23/12/21', 'm-1', '03/06/2022',
    <Box className='action_container'>
      <Link to="/viewsource"><RemoveRedEyeIcon /></Link>
      <Link to="/editconnection"><EditIcon /></Link>
    </Box>),
  createData(<img style={{ height: '25px' }} src={test} alt="" />, 'src_name_10', 'connection_10', 'designing', 'user-10', '23/12/21', 'm-1', '03/06/2022',
    <Box className='action_container'>
      <Link to="/viewsource"><RemoveRedEyeIcon /></Link>
      <Link to="/editconnection"><EditIcon /></Link>
    </Box>),
  createData(<img style={{ height: '25px' }} src={test} alt="" />, 'src_name_11', 'connection_11', 'deplymrnt', 'user-11', '23/12/21', 'm-1', '03/06/2022',
    <Box className='action_container'>
      <Link to="/viewsource"><RemoveRedEyeIcon /></Link>
      <Link to="/editconnection"><EditIcon /></Link>
    </Box>),
  createData(<img style={{ height: '25px' }} src={test} alt="" />, 'src_name_12', 'connection_12', 'health', 'user-12', '23/12/21', 'm-1', '03/06/2022',
    <Box className='action_container'>
      <Link to="/viewsource"><RemoveRedEyeIcon /></Link>
      <Link to="/editconnection"><EditIcon /></Link>
    </Box>),
  createData(<img style={{ height: '25px' }} src={test} alt="" />, 'src_name_13', 'connection_13', 'management', 'user-13', '23/12/21', 'm-1', '03/06/2022',
    <Box className='action_container'>
      <Link to="/viewsource"><RemoveRedEyeIcon /></Link>
      <Link to="/editconnection"><EditIcon /></Link>
    </Box>),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'provider',
    numeric: false,
    disablePadding: true,
    label: 'Provider'
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'category',
    numeric: false,
    disablePadding: false,
    label: 'Category',
  },
  {
    id: 'connection',
    numeric: false,
    disablePadding: false,
    label: 'Connection',
  },
  {
    id: 'createdby',
    numeric: true,
    disablePadding: false,
    label: 'Created by ',
  },
  {
    id: 'createdat',
    numeric: true,
    disablePadding: false,
    label: 'Created at',
  },
  {
    id: 'modifiedby',
    numeric: true,
    disablePadding: false,
    label: 'Modified by'
  },
  {
    id: 'modifiedat',
    numeric: true,
    disablePadding: false,
    label: 'Modified at'
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: true,
    label: 'Actions'
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'left' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ paddingLeft: '12px', fontWeight: '600', fontSize: '16px' }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Box>

    </Box>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('category');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box>
      <Box sx={{ width: '100%' }}>

        <Paper sx={{ width: '100%', mb: 2 }}>
          <EnhancedTableToolbar numSelected={selected.length} />
                   <TableContainer sx={{ width: '100%' , overflow: 'auto', maxHeight: '50vh'}} id="scrollbar_style">
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody >
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.name)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.name}
                        selected={isItemSelected}
                        id="scrollbar_style"
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          paddingLeft="12px"
                        >
                          {row.provider}
                        </TableCell>
                        <TableCell align="left">{row.name}</TableCell>
                        <TableCell align="left">{row.category}</TableCell>
                        <TableCell align="left">{row.connection}</TableCell>
                        <TableCell align="left">{row.createdby}</TableCell>
                        <TableCell align="left">{row.createdat}</TableCell>
                        <TableCell align="left">{row.modifiedby}</TableCell>
                        <TableCell align="left">{row.modifiedat}</TableCell>
                        <TableCell align="left">{row.actions}</TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
      </Box>
    </Box>
  );
}