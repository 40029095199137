import React, { useState } from 'react';
import { Box } from '@mui/system';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import '../../../assets/Styles/Connection/connection.scss';
import UserRights from './UserRights'
import RoleRights from './RoleRights'
import Button from '@mui/material/Button';
function createData(type, assignee) {
  return { type, assignee };
}

const rows = [
  createData('User', 'talha@invo.com'),
  createData('Admin', 'harib@invo.com'),
  createData('User', 'tayaba@invo.com')
];
const SelectUser = [
  {
    value: 'talha@invo.com',
    label: 'talha@invo.com',
  },
  {
    value: 'harib@invo.com',
    label: 'harib@invo.com',
  }

];
const SelectRole = [
  {
    value: 'acc',
    label: 'tacc',
  },
  {
    value: 'alpha',
    label: 'alpha',
  }

];
const AccessRights = () => {
  const [user, setUser] = useState('');
  const [role, setRole] = useState('');
  return (
    <Box className="access_rights" >

      <TableContainer className='table_container' component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell align="left">Assignee</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
              >
                <TableCell align="left">{row.type}</TableCell>
                <TableCell align="left">{row.assignee}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box className="access_rights_container_right" id="scrollbar_style" sx={{ maxHeight: '60vh', overflowY: "auto" }}>
        <Box >
          <TextField
            className="connection_name_field"
            select
            label="Select User"
            value={user}
            onChange={(e) => setUser(e.target.value)}
          >
            {SelectUser.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          {
            user === '' ? ('') :
              (
                <UserRights />
              )
          }
        </Box>
        <Box sx={{ marginTop: '10px' }}>
          <TextField
            className="connection_name_field"
            select
            label="Select Role"
            value={role}
            onChange={(e) => setRole(e.target.value)}
          >
            {SelectRole.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          {
            role === '' ? ('') :
              (
                <RoleRights />
              )
          }
        </Box>
        <Box>
          <Box className="connection_btn" sx={{ width: "100% !important", color: '#ffffff' }}>
            <Button variant="contained">
              Cancel
            </Button>
            <Button variant="contained">
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default AccessRights;