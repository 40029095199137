import React from "react";
import { Box } from "@mui/system";
import '../../../../assets/Styles/Target/target.scss'
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import '../../../../assets/Styles/Connection/connection.scss';
import Checkbox from '@mui/material/Checkbox';

const DialectOptions = [
    {
        value: 'email',
        label: 'Email',
    },
    {
        value: 'employeeNumber',
        label: 'EmployeeNumber',
    },
    {
        value: 'extension',
        label: 'Extension',
    },
    {
        value: 'firstName',
        label: 'firstName',
    },
    {
        value: 'jobTitle',
        label: 'jobTitle',
    },
    {
        value: 'lastName',
        label: 'lastName',
    },
    {
        value: 'officeCode',
        label: 'officeCode',
    },
    {
        value: 'reportsTo',
        label: 'reportsTo',
    }

];
const JoinView = () => {
    return (
        <Box sx={{ display: 'flex' }}>

            <Box className="connection_detail_container" >
                <Box>
                    <TextField
                        className="connection_name_field"
                        select
                        focused
                        value='employees_pipeline'
                    >
                        {DialectOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label} <Checkbox
                                    color="primary"
                                />
                            </MenuItem>
                        ))}
                    </TextField>
                </Box>
            </Box>
        </Box>
    )
};

export default JoinView;