import React from "react";
import { Box } from "@mui/system";
import '../../../../assets/Styles/Target/target.scss'
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import '../../../../assets/Styles/Source/source.scss';
import ManagementTasksTable from './DataTable';

const ManagementTasks = () => {
    return (
        <Box className="connection_container">
            <Box >
                <Toolbar style={{ paddingLeft: '0px', paddingTop: '10px', marginBottom: '20px' }}>
                    <Box className='table_header_container' >
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, ml: 0 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <TextField
                                className="search_field"
                                variant="outlined"
                                label="Search"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </Box>
                </Toolbar>
            </Box>
            <ManagementTasksTable />
        </Box>
    )
};
export default ManagementTasks;