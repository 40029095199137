import { Box } from "@mui/material";
import React from "react";
import Header from '../Components/Layout/Navbar'
import SideBar from '../Components/Layout/SideBar'
import TargetComp from '../Components/TargetComponent';
const Target = () => {
  return (
    <Box>
      <Header />
      <Box className="dashboard_container">
        <SideBar />
        <TargetComp />
      </Box>
    </Box>
  )
}

export default Target;