import { Box } from "@mui/material";
import React from "react";
import Header from '../Components/Layout/Navbar'
import SideBar from '../Components/Layout/SideBar'
import ConnectionComp from '../Components/ConnectionComponent';
const Connection = () => {
  return (
    <Box>
      <Header />
      <Box className="dashboard_container">
        <SideBar />
        <ConnectionComp />
      </Box>
    </Box>
  )
}

export default Connection;