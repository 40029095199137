import React from "react";
import { Box } from "@mui/system";
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import DBnodes from './WidgetTree';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

const steps = ['Data Source', 'Tables'];
const FilterOption = [
    {
        value: 'All',
        label: 'All',
    },
    {
        value: 'Target',
        label: 'Target',
    },
    {
        value: 'Source',
        label: 'Source',
    }

];
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'maxContent',
    minWidth: 600,
    bgcolor: 'white',
    boxShadow: 24,
    p: 4,

};
const MigrateDataModal = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [filter, setFilter] = React.useState('');
    const isStepSkipped = (step) => {
        return skipped.has(step);
    };
    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleChangeFilter = (event) => {
        setFilter(event.target.value);
    };
    return (
        <div>
            <Box>
                <Button variant="outlined" onClick={handleOpen}>Migrate</Button>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ overflow: 'scroll', height: '600px' }}
            >
                <Box sx={style}>
                    <Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Button
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                                variant='contained'
                            >
                                Back
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />
                            {activeStep === steps.length - 1 ? '' : (
                                <Button onClick={handleNext} variant='contained' disabled={filter === ''}>
                                    Next
                                </Button>
                            )}
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <Stepper sx={{ width: 400 }} activeStep={activeStep}>
                                    {steps.map((label, index) => {
                                        const stepProps = {};
                                        const labelProps = {};

                                        if (isStepSkipped(index)) {
                                            stepProps.completed = false;
                                        }
                                        return (
                                            <Step key={label} {...stepProps}>
                                                <StepLabel {...labelProps}>{label}</StepLabel>
                                            </Step>
                                        );
                                    })}
                                </Stepper>
                            </Box>
                            {activeStep === steps.length ? ('') : (
                                <React.Fragment>

                                    {activeStep === 0 && (
                                        <TextField
                                            sx={{ width: '100%', margin: '1rem' }}
                                            select
                                            label="Select Filter"
                                            value={filter}
                                            onChange={handleChangeFilter}
                                        >
                                            {FilterOption.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                    {activeStep === 1 && <DBnodes />}
                                </React.Fragment>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default MigrateDataModal;