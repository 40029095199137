import React, { useState } from "react";
import Box from '@mui/material/Box';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TextField from '@mui/material/TextField';
import TableRow from '@mui/material/TableRow';
import Modal from '@mui/material/Modal';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Paper from '@mui/material/Paper';


const LoadData = () => {
    const [edit, setEdit] = useState(true);
    const [tableName, setTableName] = useState('Some Table Name');
    const [fTableName, setFTableName] = useState('Some Table Name');
    const [pTableName, setPTableName] = useState('Some Table Name');
    const [comment, setComment] = useState('Some Table Name');

    return (
        <Box className="field_container" id="scrollbar_style" sx={{ maxHeight: 510, overflowY: 'auto' }}>
            <Box >
                <Box sx={{ width: "95%", display: 'flex', justifyContent: 'flex-end', alignItems: "center" }}>
                    <Button variant="contained" onClick={() => setEdit(false)}>Edit</Button>
                </Box>
                <Box className="statements_fields">
                    <TextField sx={{ width: '95%', marginTop: "10px" }} value={tableName} onChange={(e) => setTableName(e.target.value)} disabled={edit} id="outlined-basic" variant="outlined" label="Task" InputLabelProps={{ shrink: true }} />
                </Box>
                <Box className="statements_fields">
                    <TextField sx={{ width: '95%', marginTop: "16px" }} value={tableName} onChange={(e) => setTableName(e.target.value)} disabled={edit} id="outlined-basic" variant="outlined" label="Status" InputLabelProps={{ shrink: true }} />
                </Box>
                <Box className="statements_fields">
                    <TextField sx={{ width: '95%', marginTop: "16px" }} value={fTableName} onChange={(e) => setFTableName(e.target.value)} disabled={edit} id="outlined-basic" variant="outlined" label="Error message" InputLabelProps={{ shrink: true }} />
                </Box>
                <Box className="statements_fields">
                    <TextField sx={{ width: '95%', marginTop: "16px" }} value={pTableName} onChange={(e) => setPTableName(e.target.value)} disabled={edit} id="outlined-basic" variant="outlined" label="Job name" InputLabelProps={{ shrink: true }} />
                </Box>
                <Box className="statements_fields">
                    <TextField sx={{ width: '95%', marginTop: "16px" }} value={comment} onChange={(e) => setComment(e.target.value)} disabled={edit} id="outlined-basic" variant="outlined" label="Last Execution at" InputLabelProps={{ shrink: true }} />
                </Box>
                <Box className="statements_fields">
                    <TextField sx={{ width: '95%', marginTop: "16px" }} value={tableName} onChange={(e) => setTableName(e.target.value)} disabled={edit} id="outlined-basic" variant="outlined" label="Next Execution at" InputLabelProps={{ shrink: true }} />
                </Box>
                {
                    edit ? ('') : (
                        <Box sx={{ width: "95%", display: 'flex', justifyContent: 'flex-end', alignItems: "center", marginTop: '1rem', marginBottom: '1rem' }}>
                            <Button sx={{ marginRight: '1rem' }} color="error" variant="contained" onClick={() => setEdit(true)}>Cancel</Button>
                            <Button variant="contained" color="success" >Update</Button>
                        </Box>
                    )
                }
            </Box>
        </Box>
    );
};

export default LoadData;