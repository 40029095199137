import React, { useState } from "react";
import { Box } from "@mui/system";
// import { Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
// import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import MigrateData from './MIgrateData';
// import '../../assets/Styles/Source/source.scss';
import DataTable from './DataTable';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'maxContent',
  minWidth: 600,
  bgcolor: 'white',
  boxShadow: 24,
  p: 4,

};

const Views = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Box className="connection_container" sx={{ width: '100%' }} >
      <Box >
        <Toolbar style={{ paddingLeft: '13px', paddingTop: '20px', marginBottom: '20px' }}>
          <Box className='table_header_container' >
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, ml: 0 },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                className="search_field"
                variant="outlined"
                label="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box>
              <Button variant="outlined" onClick={handleOpen}>Migrate</Button>
            </Box>
          </Box>
        </Toolbar>

      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: 'scroll', height: '600px' }}
      >
        <Box sx={style}>
          <MigrateData />
        </Box>
      </Modal>
      <DataTable />
    </Box>
  )
};

export default Views;