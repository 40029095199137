import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SummaryComponent from './Summary/Summary'
import Columns from "./Columns/Columns";
import PrimaryKey from "./PrimaryKey/index";
import ForiegnKeys from "./ForiegnKeys/ForiegnKeys";
import DependentViews from "./DependentViews/DependentViews";
import AccessRights from "./AccessRights/index";
import Statics from "./Statics/index";
import Tags from "./Tags/index";
import ReplicationAndCaching from "./ReplicationAndCaching/ReplicationAndCaching";
import ManagementTasks from "./ManagementTasks/index";
import TableAndViews from "./TableAndViews/TableAndViews";
import JoinView from "./JoinView/JoinView";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ padding: '1rem', background: '#0000FF', margin: "1rem" }}>
        <Breadcrumbs maxItems={3} aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to="/source" style={{ color: "white" }}>
            Source
          </Link>
          <Link underline="hover" color="inherit" href="/viewsource" style={{ color: "white" }}>
            Tables
          </Link>
          <Typography style={{ color: "white" }}> {value} </Typography>
        </Breadcrumbs>
      </Box>
      <Box
        sx={{ display: 'flex', height: "500px", width: '100%' }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          <Tab style={{ fontSize: "14px" }} label="Summary" {...a11yProps(0)} />
          <Tab style={{ fontSize: "14px" }} label="Preview Data" {...a11yProps(1)} />
          <Tab style={{ fontSize: "14px" }} label="Columns ViewDOne" {...a11yProps(2)} />
          <Tab style={{ fontSize: "14px" }} label="Primary Key ViewDone" {...a11yProps(3)} />
          <Tab style={{ fontSize: "14px" }} label="Foriegn Keys ViewDone" {...a11yProps(4)} />
          <Tab style={{ fontSize: "14px" }} label="Dependent Views" {...a11yProps(5)} />
          <Tab style={{ fontSize: "14px" }} label="Access Rights ViewDOne" {...a11yProps(6)} />
          <Tab style={{ fontSize: "14px" }} label="Statics" {...a11yProps(7)} />
          <Tab style={{ fontSize: "14px" }} label="Tags ViewDOne" {...a11yProps(8)} />
          <Tab style={{ fontSize: "14px" }} label="Join View" {...a11yProps(9)} />
          <Tab style={{ fontSize: "14px" }} label="Dependecy Graph" {...a11yProps(10)} />
          <Tab style={{ fontSize: "14px" }} label="Replication and Caching ViewsDONe" {...a11yProps(11)} />
          <Tab style={{ fontSize: "14px" }} label="Management Tasks ViewDone" {...a11yProps(12)} />
          <Tab style={{ fontSize: "14px" }} label="Table and Views" {...a11yProps(13)} />
        </Tabs>

        <Box sx={{ width: "100%", overflow: "auto", height: '100vh' }} id="scrollbar_style" >
          <TabPanel value={value} index={0}>
            <SummaryComponent />
          </TabPanel>
          <TabPanel value={value} index={1}>
            not available
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Columns />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <PrimaryKey />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <ForiegnKeys />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <DependentViews />
          </TabPanel>
          <TabPanel value={value} index={6}>
            <AccessRights />
          </TabPanel>
          <TabPanel value={value} index={7}>
            <Statics />
          </TabPanel>
          <TabPanel value={value} index={8}>
            <Tags />
          </TabPanel>
          <TabPanel value={value} index={9}>
            <JoinView />
          </TabPanel>
          <TabPanel value={value} index={10}>
            coming soon
          </TabPanel>
          <TabPanel value={value} index={11} >
            <ReplicationAndCaching />
          </TabPanel>
          <TabPanel value={value} index={12}>
            <ManagementTasks />
          </TabPanel>
          <TabPanel value={value} index={13}>
            <TableAndViews />
          </TabPanel>
        </Box>
      </Box>
    </Box>
  );
}