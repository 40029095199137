import React, { useState } from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Logo from '../assets/media/Logo/Logo-blue.png';
import '../assets/Styles/ForgetPass/ForgetPass.scss';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link } from "react-router-dom";


const colorScehme = createTheme({
    palette: {
        primary: {
            main: '#0072FF',
        },
    },
});


const ForgetPass = () => {
    const [pass, setPass] = useState('');
    const [confirmPass, setConfirmPass] = useState('');
    return (
        <Box className="forget_wrapper">
            <Box className="side_screen" >
                <Box className="side_screen_gif"></Box>
            </Box>
            <Box className="forget_container">

                <Box className="sub_container">
                    <img src={Logo} alt="" />
                    <Box className="field_container">
                        {/* <h2>New Credentials</h2> */}
                        <h4>Your indentity has been verified</h4>
                        <h4>Set your New Password</h4>
                        <ThemeProvider theme={colorScehme}>

                            <Box className="forget_fields">
                                <TextField onChange={(e) => setPass(e.target.value)} value={pass} className="field" id="outlined-basic" variant="outlined" label="New Password" />
                            </Box>
                            <Box className="forget_fields">
                                <TextField onChange={(e) => setConfirmPass(e.target.value)} value={confirmPass} className="field" id="outlined-basic" variant="outlined" label="Confirm Password" />
                            </Box>
                        </ThemeProvider>
                    </Box>

                    <Box className="forget_btn">
                        <Link to="/forgetPasswordSuccess" style={{ textDecoration: 'none', color: 'black' }}>
                            <button >Submit</button>
                        </Link>

                        {/* <button>Submit</button> */}
                    </Box>

                    <Box className="new_account_section">
                        <p>Back to Login Page?
                            <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>
                                <span><strong>Sign in</strong></span>
                            </Link>
                        </p>
                    </Box>
                </Box>
            </Box>

        </Box>

    )
}
export default ForgetPass;