import React, { useState } from 'react';
import { Box } from "@mui/system";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Button } from '@mui/material';
import '../../../../../assets/Styles/Connection/connection.scss';

const DialectOptions = [
    {
        value: 'Accelo',
        label: 'Accelo',
    },
    {
        value: 'Access',
        label: 'Access',
    },
    {
        value: 'Act',
        label: 'Act',
    }

];


const ColumnMappings = () => {

    const [dialect, setDialect] = useState('Accelo');
    return (
        <Box >

            <Box sx={{ width: '100%' }} >
                <Box sx={{ width: '100%', marginTop: '2rem' }} >
                    <TextField
                        sx={{ width: '100%', marginTop: '1rem' }}
                        select
                        focused
                        label="Parent Column"
                        value={dialect}
                        onChange={(e) => setDialect(e.target.value)}
                    >
                        {DialectOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Box>
                <Box sx={{ width: '100%' }} >
                    <TextField
                        sx={{ width: '100%', marginTop: '1rem' }}
                        select
                        focused
                        label="Referenced Column"
                        value={dialect}
                        onChange={(e) => setDialect(e.target.value)}
                    >
                        {DialectOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Box>
                <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }} >
                        <Button variant="contained" sx={{ margin: "8px" }} color="error">
                            Cancel
                        </Button>
                        <Button variant="contained" sx={{ margin: "8px" }} color="success" >
                            Create
                        </Button>

                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
export default ColumnMappings;