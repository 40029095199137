import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import DashboardIcon from '@mui/icons-material/Dashboard';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import SourceIcon from '@mui/icons-material/Source';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SearchIcon from '@mui/icons-material/Search';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Logo from '../../assets/media/Logo/Logo-blue.png';
import '../../assets/Styles/layout/Sidebar.scss';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [sidebarToggle, setSidebarToggle] = useState(true);

    return (
        <Box className={sidebarToggle ? "sidebar_container" : "sibar_container_alter"}>
            <Box className="sidebar_sub_container">
                <Box className="toggle_sidebar">
                    <img src={Logo} alt="" />
                    {sidebarToggle ? (
                        <ArrowBackIosIcon onClick={() => setSidebarToggle(!sidebarToggle)} />
                    ) : (
                        <ArrowForwardIosIcon onClick={() => setSidebarToggle(!sidebarToggle)} />
                    )}

                </Box>
                <Link to="/home" style={{ textDecoration: 'none', color: 'black' }}>
                    <Box className={`sidebar_elements ${1 === 'active' ? ' checked' : ''}`} onClick={() => (1)}
                    ><DashboardIcon />
                        <span>
                            Dashboard
                        </span>
                    </Box>
                </Link>
                <Link to="/connection" style={{ textDecoration: 'none', color: 'black' }}>
                    <Box className={`sidebar_elements ${"active" === 2 ? ' checked' : ''}`} onClick={() => (2)}><ElectricalServicesIcon />
                        <span>
                            Connect
                        </span>
                    </Box>
                </Link>
                <Link to="/source" style={{ textDecoration: 'none', color: 'black' }} >
                    <Box className={`sidebar_elements ${'active' === 3 ? ' checked' : ''}`} onClick={() => (3)}><SourceIcon />
                        <span>
                            Source
                        </span>
                    </Box>
                </Link>
                <Link to="/analyze" style={{ textDecoration: 'none', color: 'black' }}>
                    <Box className={`sidebar_elements ${'active' === 4 ? ' checked' : ''}`} onClick={() => (4)}><AutoGraphIcon />
                        <span>
                            Analyze
                        </span>
                    </Box>
                </Link>
                <Link to="/target" style={{ textDecoration: 'none', color: 'black' }}>
                    <Box className={`sidebar_elements ${'active' === 5 ? ' checked' : ''}`} onClick={() => (5)}><AccountTreeIcon />
                        <span>
                            Target
                        </span>
                    </Box>
                </Link>
                <Link to="/workFlow" style={{ textDecoration: 'none', color: 'black' }}>
                    <Box className={`sidebar_elements ${'active' === 6 ? ' checked' : ''}`} onClick={() => (6)}><AccessTimeIcon />
                        <span>
                            Workflow
                        </span>
                    </Box>

                </Link>
                {/* <Box className="sidebar_elements"><PieChartIcon/><span>Visualize</span></Box> */}
                <Link to="/catalog" style={{ textDecoration: 'none', color: 'black' }}>
                    <Box className="sidebar_elements"><ListAltIcon />
                        <span>
                            Catalog
                        </span>
                    </Box>
                </Link>
                <Link to="/monitor" style={{ textDecoration: 'none', color: 'black' }}>
                    <Box className="sidebar_elements"><MonitorHeartIcon />
                        <span>
                            Monitor
                        </span>
                    </Box>
                </Link>
            </Box>
        </Box>
    )
}

export default Navbar;