import React from "react";
import { Box } from "@mui/system";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';

const Views = () => {
  return (
    <Box className="connection_container" sx={{ width: '100%', marginTop: '20px' }} >
      <Box >
        <Box className="statements_fields">
          <TextField sx={{ width: '95%', marginTop: "20px" }} value='f3ae6f86-d9f8-420c-9e38-1ed5d3f6ac7a' disabled={true} id="outlined-basic" variant="outlined" label="Id" InputLabelProps={{ shrink: true }} />
        </Box>
        <Box className="statements_fields">
          <TextField sx={{ width: '95%', marginTop: "20px" }} value='Load data for [Lyfdata_db].[information_schema_pipeline].[ADMINISTRABLE_ROLE_AUTHORIZATIONS_pipeline]' disabled={true} id="outlined-basic" variant="outlined" label="Name" InputLabelProps={{ shrink: true }} />
        </Box>
        <Box className="statements_fields">
          <TextField sx={{ width: '95%', marginTop: "20px" }} disabled={true} id="outlined-basic" variant="outlined" label="Description" InputLabelProps={{ shrink: true }} />
        </Box>
        <Box className="statements_fields">
          <TextField sx={{ width: '95%', marginTop: "20px" }} disabled={true} id="outlined-basic" variant="outlined" label="Owner" value='junaid.akram@invozone.com' InputLabelProps={{ shrink: true }} />
        </Box>
        <Box className="statements_fields">
          <TextField sx={{ width: '95%', marginTop: "20px" }} disabled={true} id="outlined-basic" variant="outlined" label="Created at" value='2022-03-15 13:24:32' InputLabelProps={{ shrink: true }} />
        </Box>
        <Box className="statements_fields">
          <TextField sx={{ width: '95%', marginTop: "20px" }} disabled={true} id="outlined-basic" variant="outlined" label="Modified at" value='2022-03-15 13:24:32' InputLabelProps={{ shrink: true }} />
        </Box>
        <Box>
          <Checkbox
            color="primary"
            disabled={true}
            checked={true}
          />
          <span>
            Enabled
          </span>
        </Box>
        <Box sx={{ width: "95%", display: 'flex', justifyContent: 'flex-end', alignItems: "center", marginTop: '1rem' }}>
          <Button sx={{ marginRight: '1rem' }} disabled color="error" variant="contained" >Cancel WorkFlow</Button>
          <Button variant="contained" color="success" >Start WorkFlow</Button>
        </Box>
      </Box>
    </Box>
  )
};

export default Views;