import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import '../../../../SourceComponent/source.scss'

function createModalAccessRightsData(type, assignee) {
    return {
        type,
        assignee
    };
}
const dataaccessrightrows = [
    createModalAccessRightsData('type', 'assignee'),
    createModalAccessRightsData('type', 'assignee'),
    createModalAccessRightsData('type', 'assignee'),
    createModalAccessRightsData('type', 'assignee'),
    createModalAccessRightsData('type', 'assignee'),
    createModalAccessRightsData('type', 'assignee'),
    createModalAccessRightsData('type', 'assignee'),
];
const AccessRights = () => {
    return (
        <TableContainer component={Paper} sx={{ overflowY: 'auto !important', height: '360px' }} id="scrollbar_style">
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Type</TableCell>
                        <TableCell>Assignee</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataaccessrightrows.map((dtrow) => (
                        <TableRow
                            key={dtrow.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {dtrow.type}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {dtrow.assignee}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default AccessRights;