import * as React from 'react';
import Box from '@mui/material/Box';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Modal from '@mui/material/Modal';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import '../../../SourceComponent/source.scss'
import Email from './Tabs/Email';
import PreviewData from './Tabs/PreviewData';
import AccessRights from './Tabs/AccessRights';
import Tags from './Tabs/Tags';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    width: '500px',
};

const ColumnDetailViewModal = () => {
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <Box className='action_container' sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={handleOpen}>
                <RemoveRedEyeIcon />
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style }}>
                    <Box>
                        <Box className="panels" sx={{ width: 'max-content', typography: 'body1' }}>
                            <TabContext isVertical value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList isVertical onChange={handleChange} aria-label="lab API tabs example " style={{ background: 'white' }}>
                                        <Tab label="Email" value="1" />
                                        <Tab label="Preview Data" value="2" />
                                        <Tab label="Access rights" value="3" />
                                        <Tab label="Tags" value="4" />
                                    </TabList>
                                </Box>
                                <TabPanel className="container" value="1" >
                                    <Email />
                                </TabPanel>
                                <TabPanel className="container" value="2">
                                    <PreviewData />
                                </TabPanel>
                                <TabPanel className="container" value="3">
                                    <AccessRights />
                                </TabPanel>
                                <TabPanel className="container" value="4">
                                    <Tags />
                                </TabPanel>
                            </TabContext>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
};

export default ColumnDetailViewModal;