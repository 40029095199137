import React, { useState } from "react";
import { Box } from "@mui/system";
import '../../../assets/Styles/Target/target.scss';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Steps from './WorkFlowDetailTabs/Steps'
import Views from "./WorkFlowDetailTabs/Job";
import ExecutionHistory from "./WorkFlowDetailTabs/ExecutionHistory";
import Tags from "./WorkFlowDetailTabs/Tags/Tags";
import AccessRights from "./WorkFlowDetailTabs/AccessRights/AccessRights";
import Schedules from "./WorkFlowDetailTabs/Schedules";
const WorkFlowDetailComp = () => {

  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box id="scrollbar_style" className="main_cont">
      <Box class="second_cont">
        <Box className="new_connection_container">
          <Box className="panels" sx={{ width: '100%', overflow: 'hidden', typography: 'body1' }}>
            <TabContext isVertical value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList isVertical onChange={handleChange} aria-label="lab API tabs example " style={{ background: 'white', widht: '100%' }}>
                  <Tab label="Job" value="1" />
                  <Tab label="Steps" value="2" />
                  <Tab label="Execution History" value="3" />
                  <Tab label="Schedules" value="4" />
                  <Tab label="Access Rights" value="5" />
                </TabList>
              </Box>
              <TabPanel className="container" style={{ padding: '0px', width: '100%', height: '100%' }} value="1">
                <Views />
              </TabPanel>
              <TabPanel className="container" style={{ padding: '0px', width: '100%', height: '100%' }} value="2">
                <Steps />
              </TabPanel>
              <TabPanel className="container" style={{ padding: '0px', width: '100%', height: '100%' }} value="3">
                <ExecutionHistory />
              </TabPanel>
              <TabPanel className="container" style={{ padding: '0px', width: '100%', height: '100%' }} value="4">
                <Schedules />
              </TabPanel>
              <TabPanel className="container" style={{ padding: '0px', width: '100', height: '100%' }} value="5">
                <AccessRights />
              </TabPanel>
              <TabPanel className="container" style={{ padding: '0px', width: '100', height: '100%' }} value="6">
                <Tags />
              </TabPanel>
            </TabContext>
          </Box>
        </Box>
      </Box>
    </Box>
  )
};

export default WorkFlowDetailComp;