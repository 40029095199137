import { Box } from "@mui/material";
import React from "react";
import Header from '../Components/Layout/Navbar'
import SideBar from '../Components/Layout/SideBar'
import SourceComp from '../Components/SourceComponent';
const Source = () => {
  return (
    <Box>
      <Header />
      <Box className="dashboard_container">
        <SideBar />
        <SourceComp />
      </Box>
    </Box>
  )
}

export default Source;