import { Box } from "@mui/material";
import React from "react";
import Header from '../Components/Layout/Navbar'
import SideBar from '../Components/Layout/SideBar'
import WorkFlowComp from '../Components/WorkFlowComponent';
const WorkFlow = () => {
  return (
    <Box>
      <Header />
      <Box className="dashboard_container">
        <SideBar />
        <WorkFlowComp />
      </Box>
    </Box>
  )
}

export default WorkFlow;