import React from "react";
import { Box } from "@mui/system";
import '../../../../assets/Styles/Target/target.scss'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import '../../../../assets/Styles/Source/source.scss';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    border: '2px #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    height: 'auto',
};
const AddCalculatedColumnModal = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box className='action_container' sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={handleOpen} variant="contained">
                Add Calculated column
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style }}>
                    <Box>
                        <Box className="panels" sx={{ width: 'max-content', typography: 'body1' }}>

                            <Box className="connection_detail_container" sx={{ paddingTop: '1rem' }}>
                                <Box sx={{ marginTop: "1rem" }}>
                                    <TextField
                                        sx={{ width: '100%' }}
                                        id="outlined-basic"
                                        required
                                        variant="outlined" focused label="Virtual column name" />
                                </Box>
                                <Box sx={{ marginTop: "1rem" }}>
                                    <TextField
                                        sx={{ width: '100%' }}
                                        id="outlined-basic"
                                        required
                                        variant="outlined" focused label="Virtual column type" />
                                </Box>
                                <Box sx={{ marginTop: "1rem" }}>
                                    <TextField
                                        sx={{ width: '100%' }}
                                        id="outlined-basic"

                                        variant="outlined" focused label="Comment" />
                                </Box>
                                <Box sx={{ marginTop: "1rem" }}>
                                    <TextField
                                        sx={{ width: '100%' }}
                                        id="outlined-basic"
                                        required
                                        variant="outlined" focused label="Read Formula" />
                                </Box>

                                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }} >
                                    <Button variant="contained" onClick={handleClose} sx={{ margin: "8px" }} color="error">
                                        Cancel
                                    </Button>
                                    <Button variant="contained" sx={{ margin: "8px" }} color="success" >
                                        Create
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
};

export default AddCalculatedColumnModal;