const counter = (state = 0, action) => {
    switch (action.type) {
      case 'inc':
        return (state = state + 5);
        case 'dec':
            return ( state = state - 5)
      default:
        return state;
    }
  };
  export default counter;
  