import React from "react";
import { Box } from "@mui/system";
import '../../../../assets/Styles/Target/target.scss'
import TextField from '@mui/material/TextField';
import RepModal from './Modal';

const ReplicationAndCaching = () => {
    return (
        <Box className="field_container" sx={{ maxHeight: "100vh !important" }} id="scrollbar_style">
            <Box>
                <RepModal />
            </Box>
            <Box className="statements_fields">
                <TextField className="field" value="Cache/Replication mode" disabled id="outlined-basic" variant="outlined" label="Cache/Replication Mode" InputLabelProps={{ shrink: true }} />
            </Box>
            <Box className="statements_fields">
                <TextField className="field" value="Target Table" disabled id="outlined-basic" variant="outlined" label="Target Table" InputLabelProps={{ shrink: true }} />
            </Box>
            <Box className="statements_fields">
                <TextField className="field" value="Cache Table Status" disabled id="outlined-basic" variant="outlined" label="Cache Table Status" InputLabelProps={{ shrink: true }} />
            </Box>
            <Box className="statements_fields">
                <TextField className="field" value="Last data load" disabled id="outlined-basic" variant="outlined" label="Last Data Load" InputLabelProps={{ shrink: true }} />
            </Box>
            <Box className="statements_fields">
                <TextField className="field" value="Incremental marker column" disabled id="outlined-basic" variant="outlined" label="Incremental Marker Column" InputLabelProps={{ shrink: true }} />
            </Box>
            <Box className="statements_fields">
                <TextField className="field" value="Last incremental marker value" disabled id="outlined-basic" variant="outlined" label="Last Incremental Marker Value" InputLabelProps={{ shrink: true }} />
            </Box>
            <Box className="statements_fields">
                <TextField className="field" value="Cache refresh mode" disabled id="outlined-basic" variant="outlined" label="Cache refresh mode" InputLabelProps={{ shrink: true }} />
            </Box>
            <Box className="statements_fields">
                <TextField className="field" value="Target table cleaning" disabled id="outlined-basic" variant="outlined" label="Target table cleaning" InputLabelProps={{ shrink: true }} />
            </Box>
        </Box>
    )
};

export default ReplicationAndCaching;