import React, { useState } from "react";
import { Box } from "@mui/system";
import '../../../assets/Styles/Target/target.scss';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tables from './TargetDetailTabs/Tables/Tables'
import Views from "./TargetDetailTabs/Views/Views";
import Schemas from "./TargetDetailTabs/Schemas/Schemas";
import Tags from "./TargetDetailTabs/Tags/Tags";
import AccessRights from "./TargetDetailTabs/AccessRights/AccessRights";
import ManagementTasks from "./TargetDetailTabs/ManagementTasks/ManagementTasks";
const TargetDetail = () => {
  const [value, setValue] = useState('1');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box id="scrollbar_style" className="main_cont">
      <Box class="second_cont">
        <Box className="new_connection_container">
          <Box className="panels" sx={{ width: '100%', overflow: 'hidden', typography: 'body1' }}>
            <TabContext isVertical value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList isVertical onChange={handleChange} aria-label="lab API tabs example " style={{ background: 'white', widht: '100%' }}>
                  <Tab label="Tables" value="1" />
                  <Tab label="Views" value="2" />
                  <Tab label="Schemas" value="3" />
                  <Tab label="Access Rights" value="4" />
                  <Tab label="Management Tasks" value="5" />
                  <Tab label="Tags" value="6" />
                </TabList>
              </Box>
              <TabPanel className="container" style={{ padding: '0px', width: '100%', height: '100%' }} value="1">
                <Tables />
              </TabPanel>
              <TabPanel className="container" style={{ padding: '0px', width: '100%', height: '100%' }} value="2">
                <Views />
              </TabPanel>
              <TabPanel className="container" style={{ padding: '0px', width: '100%', height: '100%' }} value="3">
                <Schemas />
              </TabPanel>
              <TabPanel className="container" style={{ padding: '0px', width: '100', height: '100%' }} value="4">
                <AccessRights />
              </TabPanel><TabPanel className="container" style={{ padding: '0px', width: '100%', height: '100%' }} value="5">
                <ManagementTasks />
              </TabPanel>
              <TabPanel className="container" style={{ padding: '0px', width: '100', height: '100%' }} value="6">
                <Tags />
              </TabPanel>
            </TabContext>
          </Box>
        </Box>
      </Box>
    </Box>
  )
};

export default TargetDetail;