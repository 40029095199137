import React from 'react';
import { Box } from "@mui/system";
import '../../../../assets/Styles/Connection/connection.scss'
import { TargetProviderHeader } from './TargetProviderHeader';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import DummyProvider from '../../../../assets/media/88.png';

const TargetProvider = () => {
    const [value, setValue] = React.useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <Box className="provider_section">

                <TargetProviderHeader />
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="Target" value="1" />
                            </TabList>
                        </Box>
                        <TabPanel value="1" sx={{ padding: 0 }}>
                            <Box className="provider_DB_container">

                                <Box className="provider">
                                    <Box className="provider_icon">
                                        <img src={DummyProvider} alt="" />
                                    </Box>

                                    <Box className="provider_name">
                                        Target 1
                                    </Box>
                                </Box>
                                <Box className="provider">
                                    <Box className="provider_icon">
                                        <img src={DummyProvider} alt="" />
                                    </Box>

                                    <Box className="provider_name">
                                        Target 2
                                    </Box>
                                </Box>
                                <Box className="provider">
                                    <Box className="provider_icon">
                                        <img src={DummyProvider} alt="" />
                                    </Box>

                                    <Box className="provider_name">
                                        Target 3
                                    </Box>
                                </Box>
                                <Box className="provider">
                                    <Box className="provider_icon">
                                        <img src={DummyProvider} alt="" />
                                    </Box>

                                    <Box className="provider_name">
                                        Target 4
                                    </Box>
                                </Box>
                                <Box className="provider">
                                    <Box className="provider_icon">
                                        <img src={DummyProvider} alt="" />
                                    </Box>

                                    <Box className="provider_name">
                                        Target 5
                                    </Box>
                                </Box>
                                <Box className="provider">
                                    <Box className="provider_icon">
                                        <img src={DummyProvider} alt="" />
                                    </Box>

                                    <Box className="provider_name">
                                        Target 6
                                    </Box>
                                </Box>
                            </Box>
                        </TabPanel>
                    </TabContext>
                </Box>

            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '50%', background: "#FAFAFA" }} >
                <h2>Choose Provider Guide</h2>
                <p>(Coming Soon)</p>

            </Box>
        </Box>
    )
}
export default TargetProvider;
