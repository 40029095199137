import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import CloseIcon from '@mui/icons-material/Close';
import './replication.scss';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  height: 600,
  bgcolor: 'white',
  border: '2px #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
function createData(columnname, distinctcount, averagelengthbytes, calculatestatistics, crossicon) {
  return {
    columnname,
    distinctcount,
    averagelengthbytes,
    calculatestatistics,
    crossicon
  };
}

const rows = [
  createData(<Checkbox color="primary" />, <Checkbox color="primary" />, 'averagelengthbytes',
    <FormControl fullWidth>
      <Select
        id="demo-simple-select"
      >
        <MenuItem value={10}>1</MenuItem>
        <MenuItem value={20}>2</MenuItem>
        <MenuItem value={30}>3</MenuItem>
        <MenuItem value={40}>4</MenuItem>
      </Select>
    </FormControl>,
    <CloseIcon />),
];

export default function RepModal(props) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('1');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Button variant="contained" sx={{ marginBottom: "10px" }} onClick={handleOpen}>
        Edit
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style }}>
          <Box>
            <Box className="panels" sx={{ width: 'max-content', typography: 'body1' }}>
              <TabContext isVertical value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Typography sx={{ margin: '14px', fontSize: '20px', fontWeight: 600, textAlign: 'center' }}>Caching & Data Replication</Typography>
                </Box>
                <TabPanel className="container" value="1" sx={{
                  paddingLeft: '0px', paddingRight: '0px', overflowY: 'auto',
                  height: 440, paddingTop: '8px'
                }} id="scrollbar_style">
                  <Grid container spacing={2} sx={{ width: '95%', margin: '0 auto' }}>
                    <Grid item xs={12} sx={{ paddingLeft: '0px !important' }} >
                      <FormControl >
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="radio-buttons-group">
                          <FormControlLabel value="Real-time Data Pipeline (pass-through view)" control={<Radio />} label="Real-time Data Pipeline (pass-through view)" sx={{ paddingBottom: '5px' }} />
                          <FormControlLabel value=" Replicate To a Target Table" control={<Radio />} label=" Replicate To a Target Table" sx={{ paddingBottom: '5px' }} />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sx={{ paddingTop: '20px', borderTop: 1, borderColor: 'divider', paddingLeft: '0px !important' }} >
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Target Schema</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Target Schema"
                          onChange={handleChange}
                        >
                          <MenuItem value={10}>Insert</MenuItem>
                          <MenuItem value={20}>Update- SCD Type 1</MenuItem>
                          <MenuItem value={30}>Update- SCD Type 2</MenuItem>
                          <MenuItem value={40}>Update- SCD Type 3</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sx={{ paddingTop: '20px', paddingLeft: '0px !important' }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Target Table</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Target Table"
                          onChange={handleChange}
                        >
                          <MenuItem value={10}>Insert</MenuItem>
                          <MenuItem value={20}>Update- SCD Type 1</MenuItem>
                          <MenuItem value={30}>Update- SCD Type 2</MenuItem>
                          <MenuItem value={40}>Update- SCD Type 3</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sx={{ paddingTop: '20px', paddingLeft: '0px !important' }} >
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Action Type</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Action Type"
                          onChange={handleChange}
                        >
                          <MenuItem value={10}>Insert</MenuItem>
                          <MenuItem value={20}>Update- SCD Type 1</MenuItem>
                          <MenuItem value={30}>Update- SCD Type 2</MenuItem>
                          <MenuItem value={40}>Update- SCD Type 3</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sx={{ paddingTop: '20px', paddingBottom: '20px', paddingLeft: '0px !important' }} >
                      <TableContainer component={Paper} sx={{ maxHeight: 310 }} id="scrollbar_style">
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="center"><b>ID</b></TableCell>
                              <TableCell align="center"><b>Trigger</b></TableCell>
                              <TableCell align="center"><b>Source Column</b></TableCell>
                              <TableCell align="center"><b>Target Column</b></TableCell>
                              <TableCell align="center"><b>Remove</b></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rows.map((row) => (
                              <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell align="center">{row.columnname}</TableCell>
                                <TableCell align="center">{row.distinctcount}</TableCell>
                                <TableCell align="center">{row.averagelengthbytes}</TableCell>
                                <TableCell align="center">{row.calculatestatistics}</TableCell>
                                <TableCell align="center">{row.crossicon}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                    <Grid item xs={12} sx={{ paddingTop: '20px', paddingBottom: '20px', borderTop: 1, borderColor: 'divider', paddingLeft: '0px !important' }}>
                      <Button variant="outlined">Select target table to display columns</Button>
                    </Grid>
                    <Grid item xs={12} sx={{ paddingTop: '20px', borderTop: 1, borderColor: 'divider', paddingLeft: '0px !important' }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Cache refresh mode</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Cache refresh mode"
                          onChange={handleChange}
                        >
                          <MenuItem value={10}>Full</MenuItem>
                          <MenuItem value={20}>Incremental(Source)</MenuItem>
                          <MenuItem value={30}>Incremental(Target)</MenuItem>
                          <MenuItem value={40}>Lyftron Job Marker</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sx={{ paddingTop: '20px', borderTop: 1, borderColor: 'divider', paddingLeft: '0px !important' }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Incremental marker column*</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Incremental marker column*"
                          onChange={handleChange}
                        >
                          <MenuItem value={10}>Full</MenuItem>
                          <MenuItem value={20}>Incremental(Source)</MenuItem>
                          <MenuItem value={30}>Incremental(Target)</MenuItem>
                          <MenuItem value={40}>Lyftron Job Marker</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sx={{ paddingTop: '20px', borderTop: 1, borderColor: 'divider', paddingLeft: '0px !important' }}>
                      <TextField id="outlined-basic" label="Incremental marker value*" variant="outlined" sx={{ width: '100%' }} />
                    </Grid>
                    <Grid item xs={12} sx={{ paddingTop: '20px', paddingLeft: '0px !important' }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Target Table Cleaning</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Target Table Cleaning"
                          onChange={handleChange}
                        >
                          <MenuItem value={10}>Recreate - (Drop | Create)</MenuItem>
                          <MenuItem value={20}>Delete From</MenuItem>
                          <MenuItem value={30}>Truncate Table</MenuItem>
                          <MenuItem value={40}>No Cleaning</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </TabPanel>
                <Box sx={{ borderTop: 1, borderColor: 'divider', display: 'flex', justifyContent: 'end' }}>
                  <Button variant="outlined" size="large" onClick={handleClose} sx={{ marginRight: '10px', width: '100px', textAlign: 'center', marginTop: '10px' }}>
                    Cancel
                  </Button>
                  <Button variant="contained" size="large" sx={{ width: '100px', marginTop: '10px' }}>
                    Save
                  </Button>
                </Box>
              </TabContext>

            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}