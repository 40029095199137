import { Box } from "@mui/material";
import React from "react";
import Header from '../../../Components/Layout/Navbar'
import SideBar from '../../../Components/Layout/SideBar'
import WorkFlowDetailComp from "../../../Components/WorkFlowComponent/WorkFlowDetail";

const WorkFlowDetail = () => {
    return (
        <Box>
            <Header />
            <Box className="dashboard_container">
                <SideBar />
                <WorkFlowDetailComp />
            </Box>
        </Box>
    )
}

export default WorkFlowDetail;