import { Box } from "@mui/material";
import React, { useState } from "react";
import Checkbox from '@mui/material/Checkbox';
import '../../assets/Styles/Catalog/catalog.scss';
const Catalog = () => {
    const [objectValue, setObjectValue] = useState(false);
    const [objectValue1, setObjectValue1] = useState(false);
    const [objectValue2, setObjectValue2] = useState(false);
    const [objectValue3, setObjectValue3] = useState(false);
    const [objectValue4, setObjectValue4] = useState(false);
    return (
        <Box className="leftBar" id="scrollbar_style">
            <Box className="LeftBar_modules">
                <h3>Tags</h3>
                <hr />
                <p>
                    PI (6)
                </p>
                <p>
                    PI (6)
                </p>
                <p>
                    PI (6)
                </p>
            </Box>
            <Box className="LeftBar_modules">
                <h3>Object Types</h3>
                <hr />
                <Box>
                    <Checkbox
                        color="primary"
                        checked={objectValue}
                        onClick={() => setObjectValue(!objectValue)}

                    />
                    <span>
                        Databases (4)
                    </span>
                </Box>
                <Box>
                    <Checkbox
                        color="primary"
                        checked={objectValue1}
                        onClick={() => setObjectValue1(!objectValue1)}
                    />
                    <span>
                        Tables (4)
                    </span>
                </Box>
                <Box>
                    <Checkbox
                        color="primary"
                        checked={objectValue2}
                        onClick={() => setObjectValue2(!objectValue2)}
                    />
                    <span>
                        Schema (4)
                    </span>
                </Box>
                <Box className="LeftBar_modules">
                    <h3>Statistics</h3>
                    <hr />

                    <Box>
                        <Checkbox
                            color="primary"
                            checked={objectValue3}
                            onClick={() => setObjectValue3(!objectValue3)}
                        />
                        <span>
                            0 - 0 (5)
                        </span>
                    </Box>
                    <Box>
                        <Checkbox
                            color="primary"
                            checked={objectValue4}
                            onClick={() => setObjectValue4(!objectValue4)} ss
                        />
                        <span>
                            1M - 10M
                        </span>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Catalog;