import { Box } from "@mui/material";
import React from "react";
import Header from '../Components/Layout/Navbar';
import SideBar from '../Components/Layout/SideBar';
import CatalogLeftBar from "../Components/Catalog/CatalogLeftbar";
import CatalogRightBar from "../Components/Catalog/CatalogRightBar";
import DataTable from '../Components/Catalog/DataTable';
import '../assets/Styles/Catalog/catalog.scss';
const Catalog = () => {
  return (
    <Box>
      <Header />
      <Box className="dashboard_container" >
        <SideBar />
        <Box id="scrollbar_style" className="main_cont">
          <Box class="second_cont">
            <Box className="Catalog_comp_container" sx={{ width: '90%' }}>
              <CatalogLeftBar />
              <Box id="scrollbar_style" className="Table_container">
                <DataTable />
              </Box>
              <CatalogRightBar />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Catalog;